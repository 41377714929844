import dayjs from "dayjs";
import { getVehicleBatch } from "@/api/car.js";

const initDay = "2021-07-01 00:00::00";
const today = dayjs();
const totalDays = today.diff(initDay, "days");
// const carNums = parseInt(Math.random() * 20) + 20;
const cars = [];
let myCars = [];
// const myLocation = [
//   {
//     location: "36.686667,117.084865",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.679986,117.052821",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.667399,117.114709",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.680315,117.051045",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.685566,117.064994",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.654297,116.987704",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.685566,117.064994",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.68,117.09",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.68,117.10",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.66,117.00",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.68,117.16",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.69,117.13",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.69,116.96",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.68,117.12",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "37.29,117.15",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "37.29,117.16",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "37.29,117.17",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.22,117.69",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.22,117.68",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.21,117.68",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.12,120.39",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.12,120.38",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.12,120.36",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.13,120.40",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.09,120.38",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.09,120.38",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.47,115.98",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.46,116.01",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.45,116.03",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.43,116.00",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.44,116.04",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.25,115.42",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.24,115.43",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.25,115.43",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.23,115.44",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.82,117.27",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.81,117.28",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.80,117.27",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.72,117.17",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.71,117.19",
//     vehicleStatus: 1,
//     vin: "",
//   },
//   {
//     location: "36.70,117.18",
//     vehicleStatus: 1,
//     vin: "",
//   },
  
// ];
const locations = [
  // [36.686667,117.084865],
  // [36.679986,117.052821],
  // [36.667399,117.114709],
  // [36.681266,117.053027],
  // [36.680315,117.051045],
  // [36.681338,117.053019],
  // [36.679910,117.052890],
  // [36.665151,117.105327],
  // [36.679842,117.052867],
  // [36.680178,117.052936],
  // [36.660851,116.993681],
  // [36.680243,117.064309],
  // [36.67862,117.065226],
  // [36.685566,117.064994],
  // [36.681137,117.042235],
  // [36.680509,117.064282],
  // [36.671888,117.069557],
  // [36.665547,117.077195],
  // [36.675838,117.08069],
  // [36.670642,117.070699],
  // [36.670642,117.070699],
  // [36.654297,116.987704],
  // [36.654297,116.987704],
  // [36.650451,116.990616],
  // [36.654297,116.987704],
  // [36.659245,116.997442],
  // [36.680864,117.077391],
  // [36.644214,117.09362],
  // [36.658811,116.988919],
  // [36.623212,116.991901],
  // [36.679848,117.08582],
  // [36.666597,117.059028],
  // [36.67605,117.043889],
  // [36.680842,117.117839],
  // [36.63056,117.108056],
  // [36.626866,116.999167],
  // [36.738155,117.046797],
  // [36.629644,117.034058],
  // [36.637834,117.068694],
  // [36.67723,117.06655],
  // [36.682018,117.037144],
  // [36.680867,117.077394],
  // [36.670016,117.047635],
  // [36.652899,117.028342],
  // [36.69577,117.098804],
];
const mileageTrendLoc = new Map();
const rankingLoc = [];

const randomStr = (type) => {
  const numStr =
    type === "number"
      ? parseInt(Math.random() * 9) + 48
      : parseInt(Math.random() * 25) + 65;

  return String.fromCharCode(numStr);
};
const getNumPlate = (prefix = "鲁AD", suffixLen = 5) => {
  let numPate = "";

  for (let i = 0; i < suffixLen; i++) {
    const type = Math.random() < 0.5 ? "number" : "letter";

    numPate += randomStr(type);
  }

  return prefix + numPate;
};

export default {
  init() {
    getVehicleBatch().then((res) => {
      res.forEach((item) => {
        const { location } = item;
        myCars = locations.push(location);
      });
      myCars = res;
    });
  },
  dataScreening() {
    const today = dayjs();
    const minutes = today.minute();
    const hours = today.hour();

    const protectTimes = hours * 3 + parseInt(minutes / 20);
    const totalProtectTimes = totalDays * 72 + protectTimes;
    const vehicleTotalCount = parseInt(totalDays / 7) + 20;
    const totalDistance = totalDays * 160 + parseInt((hours / 24) * 80) * 2;

    return {
      vehicleTotalCount,
      protectTimes,
      totalProtectTimes,
      totalDistance,
    };
  },
  avgSpeed() {
    return parseFloat((Math.random() * 20 + 20).toFixed(2));
  },
  vehiclesRealTimeData() {
    const res = myCars;
    console.log("res,", res);
    // vehiclesRealTimeData(search) {
    // const params = new URLSearchParams(search);
    // console.log(params.keys());

    const timeStamp = +new Date();
    const randomNum = 5;
    const _carNums = 178;
    const _cars = [];
    let _randomNum = 0;

    res.forEach((item, i) => {
      const { vehicleStatus, vin, location } = item;
      if (vin !== "HJZ1BA1E5M1000666" && vin !== "HJZ1BA1E8M1000757"&&vin!=='HJZ1BA1EXM1000775') {
        const strI = i < 10 ? `0${i}` : `${i}`;
        const r = Math.random();
        let chargeStatus = null;
        if (r > 0.1 && r < 0.8) {
          chargeStatus = 3;
        } else if (r > 0.8) {
          chargeStatus = 1;
        }
        if (cars[i]) {
          if (timeStamp - cars[i].timeStamp > 3 * 60 * 1000) {
            cars[i] = {
              ...cars[i],
              vehicleStatus,
              chargeStatus,
              timeStamp,
            };
          }
        } else {
          cars[i] = {
            numPlate: `鲁AD500${strI}`,
            totalMiles: 0,
            driveMiles: 0,
            speed: 0,
            vehicleId: `61655705e4b09fe46efab7${strI}`,
            power: 0,
            location: location,
            vin,
            vehicleStatus,
            chargeStatus,
            timeStamp,
          };
        }

        if (r < randomNum / _carNums && _randomNum < randomNum) {
          _randomNum += 1;
        } else {
          _cars.push(cars[i]);
        }
        // return
      }
    });

    // for(let i = 0; i < _carNums; i++) {
    //   const strI = i < 10 ? `0${i}` : `${i}`;
    //   const r = Math.random();
    //   let vehicleStatus = null;
    //   let chargeStatus = null;

    //   if (r > 0.1 && r < 0.7) {
    //     vehicleStatus = 1
    //   } else if (r > 0.7) {
    //     vehicleStatus = 2;
    //   }

    //   if (r > 0.1 && r < 0.8) {
    //     chargeStatus = 3;
    //   } else if (r > 0.8) {
    //     chargeStatus = 1;
    //   }

    //   // console.log(locations);

    //   if (cars[i]) {
    //     if (timeStamp - cars[i].timeStamp > 3 * 60 * 1000) {
    //       cars[i] = {
    //         ...cars[i],
    //         vehicleStatus,
    //         chargeStatus,
    //         timeStamp,
    //       }
    //     }
    //   } else {
    //     cars[i] = {
    //       numPlate: `鲁AD500${strI}`,
    //       totalMiles: 0,
    //       driveMiles: 0,
    //       speed: 0,
    //       vehicleId: `61655705e4b09fe46efab7${strI}`,
    //       power: 0,
    //       location: locations[i],
    //       vehicleStatus,
    //       chargeStatus,
    //       timeStamp,
    //     };
    //   }

    //   if ((r < randomNum / _carNums) && (_randomNum < randomNum)) {
    //     _randomNum += 1;
    //   } else {
    //     _cars.push(cars[i]);
    //   }
    // }

    return _cars;
  },
  expressPoint() {
    return [
      {
        key: "6211f0cde4b0b694c23a6e86",
        location: "36.661530,117.137730",
        name: "济南历下天泰家园万物升店",
        owner: null,
      },
      {
        key: "6211f090e4b0b694c23a6e85",
        location: "36.678955,117.151949",
        name: "金茂府",
        owner: null,
      },
      {
        key: "61b9ae10e4b0ad7afb7a5bc2",
        location: "36.580554,117.039126",
        name: "紫云府",
        owner: null,
      },
      {
        key: "61b9add0e4b0ad7afb7a5bc1",
        location: "36.596347,117.021355",
        name: "春苹超市",
        owner: null,
      },
      {
        key: "61b9aaebe4b0ad7afb7a5bc0",
        location: "36.68482,117.10702",
        name: "万科城三期北门",
        owner: null,
      },
      {
        key: "61b9aabae4b0ad7afb7a5bbf",
        location: "36.690557,117.103965",
        name: "保利西大门",
        owner: null,
      },
      {
        key: "61b9aa85e4b0ad7afb7a5bbe",
        location: "36.670307,117.109133",
        name: "B13陶鲁超市",
        owner: null,
      },
      {
        key: "61b9aa14e4b0ad7afb7a5bbd",
        location: "36.676613,117.094377",
        name: "中建嘉苑",
        owner: null,
      },
      {
        key: "61b9a9dae4b0ad7afb7a5bbc",
        location: "36.700525,117.087134",
        name: "环联夜市",
        owner: null,
      },
      {
        key: "61b9a99be4b0ad7afb7a5bbb",
        location: "36.675468,117.097336",
        name: "济南历下昆仑御二区店",
        owner: null,
      },
      {
        key: "61b98c65e4b0ad7afb7a5bba",
        location: "36.610232,116.955122",
        name: "七贤庄",
        owner: null,
      },
      {
        key: "61b98c0ae4b0ad7afb7a5bb9",
        location: "36.610232,116.955122",
        name: "七贤庄",
        owner: null,
      },
      {
        key: "61b98bcae4b0ad7afb7a5bb8",
        location: "36.64912,116.955854",
        name: "凯旋新城东区",
        owner: null,
      },
      {
        key: "61b6fd2be4b0ad7afb7a5bb7",
        location: "36.662575,117.005447",
        name: "长信科技网点",
        owner: "bsht",
      },
      {
        key: "61b6fcc1e4b0ad7afb7a5bb6",
        location: "36.653783,116.980855",
        name: " 不金卡戴珊网点",
        owner: "bsht",
      },
      {
        key: "61b6fc4ee4b0ad7afb7a5bb5",
        location: "36.663266,117.020873",
        name: "哒哒后网点",
        owner: "sf",
      },
      {
        key: "61b07a62e4b016704cb79c3d",
        location: "36.686203,117.129539",
        name: "华达街邮政速递",
        owner: "zgyz",
      },
      {
        key: "6188f10ae4b0f206ab774081",
        location: "36.662480,116.976695",
        name: "海菜网点",
        owner: "qt",
      },
      {
        key: "6188c890e4b0f206ab774080",
        location: "36.687165,117.124573",
        name: "保南滩一部",
        owner: "qt",
      },
      {
        key: "6177bf72e4b0f206ab77407f",
        location: "36.644305,117.052391",
        name: "单萨成",
        owner: null,
      },
      {
        key: "61775b8ae4b0f206ab77407e",
        location: "36.70677,117.22681",
        name: "济南历城鲁能泰山七号三期店",
        owner: null,
      },
      {
        key: "61775b06e4b0f206ab77407d",
        location: "36.693982,117.215636",
        name: "济南唐冶中心店",
        owner: null,
      },
      {
        key: "6176788be4b0f2068b963055",
        location: "36.676422,117.075425",
        name: "济南市工业南路葡萄园小区",
        owner: null,
      },
      {
        key: "61767829e4b0f2068b963054",
        location: "36.692183,117.109189",
        name: "中通工业南路分部",
        owner: "zt",
      },
      {
        key: "61765b47e4b0f2068b963053",
        location: "36.582749,116.986738",
        name: "南康景苑3号楼驿站",
        owner: null,
      },
      {
        key: "61765afbe4b0f2068b963052",
        location: "36.608560,117.041730",
        name: "华润仰山二期菜鸟",
        owner: null,
      },
      {
        key: "61765aafe4b0f2068b963051",
        location: "36.577610,116.988380",
        name: "绿地新里程B13区2号楼店",
        owner: null,
      },
      {
        key: "61765a86e4b0f2068b963050",
        location: "36.585153,116.986916",
        name: "北康福邸11号楼店",
        owner: null,
      },
      {
        key: "617659e8e4b0f2068b96304f",
        location: "36.570236,116.945533",
        name: "西仙妈妈驿站",
        owner: null,
      },
      {
        key: "617659b3e4b0f2068b96304e",
        location: "36.582356,117.027465",
        name: "领秀城麓府物业中心店",
        owner: null,
      },
      {
        key: "61765985e4b0f2068b96304d",
        location: "36.598496,117.027892",
        name: "国网技术学院",
        owner: null,
      },
      {
        key: "617658bae4b0f2068b96304c",
        location: "36.615367,116.979757",
        name: "圆通领秀分部",
        owner: "yt",
      },
      {
        key: "617649dde4b0f2068b96304b",
        location: "36.719392,117.139332",
        name: "恒大城公寓K栋爱尚店",
        owner: null,
      },
      {
        key: "6167c920e4b0f2068b96304a",
        location: "36.676771,116.988544",
        name: "测试仪安擦",
        owner: null,
      },
      {
        key: "6166addbe4b0f2068b963049",
        location: "36.669754,116.992366",
        name: "的巴萨驿站",
        owner: null,
      },
      {
        key: "61663a4ee4b0f2068b963048",
        location: "36.766681,117.156184",
        name: "昌润超市（南滩头店）",
        owner: null,
      },
      {
        key: "61663a26e4b0f2068b963047",
        location: "36.711416,117.119829",
        name: "万科新里程南门",
        owner: null,
      },
      {
        key: "61663a01e4b0f2068b963046",
        location: "36.724684,117.151313",
        name: "田园新城",
        owner: null,
      },
      {
        key: "616639dde4b0f2068b963045",
        location: "36.722137,117.155871",
        name: "化肥厂宿舍",
        owner: null,
      },
      {
        key: "616639c1e4b0f2068b963044",
        location: "36.72597,117.152863",
        name: "东城苏苑",
        owner: null,
      },
      {
        key: "616639a5e4b0f2068b963043",
        location: "36.73112,117.149505",
        name: "万卷府北门",
        owner: null,
      },
      {
        key: "61663985e4b0f2068b963042",
        location: "36.722061,117.154177",
        name: "坝王路快递超市",
        owner: null,
      },
      {
        key: "61663961e4b0f2068b963041",
        location: "36.713449,117.155263",
        name: "农机小区",
        owner: null,
      },
      {
        key: "6166393ee4b0f2068b963040",
        location: "36.716652,117.159212",
        name: "鲍青苑",
        owner: null,
      },
      {
        key: "61663897e4b0f2068b96303f",
        location: "36.718613,117.156296",
        name: "鲍贤苑",
        owner: null,
      },
      {
        key: "61663850e4b0f2068b96303e",
        location: "36.720373,117.152967",
        name: "宗康超市",
        owner: null,
      },
      {
        key: "61663810e4b0f2068b96303d",
        location: "36.71934,117.149129",
        name: "高静商店",
        owner: null,
      },
      {
        key: "616637c9e4b0f2068b96303c",
        location: "36.712745,117.146997",
        name: "特首超市",
        owner: null,
      },
      {
        key: "61663760e4b0f2068b96303b",
        location: "36.762661,117.135023",
        name: "沙河三村兔喜快递超市",
        owner: null,
      },
      {
        key: "61655a4be4b0f2068b96303a",
        location: "36.667034,117.139733",
        name: "齐鲁软件学院",
        owner: null,
      },
      {
        key: "616559f5e4b0f2068b963039",
        location: "36.681030,117.123190",
        name: "济南历下丁豪广场五号楼店",
        owner: null,
      },
      {
        key: "6165598be4b0f2068b963038",
        location: "36.674654,117.118621",
        name: "济南中铁汇苑店",
        owner: null,
      },
      {
        key: "61655938e4b0f2068b963037",
        location: "36.671860,117.118664",
        name: "济南康桥颐东五号楼一单元门口店",
        owner: null,
      },
      {
        key: "616558cae4b0f2068b963036",
        location: "36.676254,117.125979",
        name: "济南历下中垠雅苑西门店",
        owner: null,
      },
      {
        key: "61416249e4b0f2068b963035",
        location: "36.739948,117.067987",
        name: "测试网点003",
        owner: "zgyz",
      },
      {
        key: "61414b4ce4b0f2068b963034",
        location: "36.865488,117.211196",
        name: "测试驿站003",
        owner: null,
      },
      {
        key: "613f0a88e4b0f2068b963033",
        location: "36.663976,117.006009",
        name: "测试驿站001",
        owner: null,
      },
      {
        key: "613f05a9e4b0f2068b963032",
        location: "36.909486,117.240628",
        name: "测试网点002",
        owner: "bsht",
      },
      {
        key: "613f0546e4b0f2068b963031",
        location: "36.727214,117.137840",
        name: "测试网点001",
        owner: "bsht",
      },
      {
        key: "613045c3e4b09a3bb77c96ac",
        location: "36.621966,117.035609",
        name: "济南历城国",
        owner: null,
      },
      {
        key: "612eeeace4b09a3bb77c96a8",
        location: "36.650218,116.913372",
        name: "1",
        owner: null,
      },
      {
        key: "612e0044e4b09a3bb77c96a4",
        location: "36.739948,117.067987",
        name: "测试网点02",
        owner: "sf",
      },
      {
        key: "612d8895e4b09a3bb77c96a3",
        location: "36.666680,117.140547",
        name: "呵呵呵呵",
        owner: "sf",
      },
      {
        key: "612cad19e4b09a3bb77c96a2",
        location: "36.679180,117.142142",
        name: "qwsc223",
        owner: null,
      },
      {
        key: "612cacf2e4b09a3bb77c96a1",
        location: "36.640724,117.022173",
        name: "1212sadasdasda",
        owner: null,
      },
      {
        key: "612cac8ee4b09a3bb77c96a0",
        location: "36.639302,117.036092",
        name: "1212dadad",
        owner: null,
      },
      {
        key: "612ca935e4b09a3bb77c969b",
        location: "36.672569,116.989916",
        name: "12sdada",
        owner: "yt",
      },
      {
        key: "612ca18be4b09a3bb77c9695",
        location: "36.671882,117.046016",
        name: "申通健信洛杉一部",
        owner: "st",
      },
      {
        key: "612ca10be4b09a3bb77c9694",
        location: "36.673500,116.958685",
        name: "测试0000001",
        owner: "sf",
      },
      {
        key: "612c7d85e4b09a3bb77c9691",
        location: "36.680920,117.073395",
        name: "钉钉测试112",
        owner: "zgyz",
      },
      {
        key: "6126002ae4b09a3bb77c968f",
        location: "36.689636,117.000600",
        name: "测试网点01",
        owner: "zgyz",
      },
      {
        key: "6125d537e4b09a3bb77c968e",
        location: "36.639128,116.986579",
        name: "测试驿站02",
        owner: null,
      },
      {
        key: "6125b250e4b0f206d0274998",
        location: "36.680713,117.018677",
        name: "测试驿站1",
        owner: null,
      },
      {
        key: "6125b207e4b0f206d0274997",
        location: "36.697588,117.125776",
        name: "山东国队虾",
        owner: null,
      },
      {
        key: "6125a262e4b0f206d0274996",
        location: "36.661160,117.134690",
        name: "ce2323",
        owner: null,
      },
      {
        key: "6125a1d6e4b0f206d0274995",
        location: "36.661160,117.134690",
        name: "测232323",
        owner: null,
      },
      {
        key: "6125a156e4b0f206d0274994",
        location: "36.674483,117.181283",
        name: "测123",
        owner: null,
      },
      {
        key: "61259fabe4b0f206d0274993",
        location: "36.669251,117.048768",
        name: "策划",
        owner: null,
      },
      {
        key: "61259eaae4b0f206d0274990",
        location: "36.719541,117.136952",
        name: "测试--0-",
        owner: null,
      },
      {
        key: "612348c2e4b09a3b15642939",
        location: "36.663266,117.020873",
        name: "测试花园店",
        owner: null,
      },
      {
        key: "611c8052e4b09a3b13f82150",
        location: "36.669125,117.047332",
        name: "ce87979",
        owner: "yt",
      },
      {
        key: "611c7b1ae4b09a3b13f8214f",
        location: "36.680175,117.028395",
        name: "ce21212",
        owner: "zgyz",
      },
      {
        key: "611c7970e4b09a3b13f8214e",
        location: "36.667719,117.080278",
        name: "cewwe2",
        owner: null,
      },
      {
        key: "6119c48ce4b09a3b13f8214b",
        location: "36.674361,117.183709",
        name: "得知",
        owner: "st",
      },
      {
        key: "6115e1d5e4b09a3b13f82149",
        location: "36.689568,117.084613",
        name: "京东驿站1",
        owner: null,
      },
      {
        key: "6115e1c0e4b09a3b13f82148",
        location: "36.674590,117.054236",
        name: "顺丰驿站1",
        owner: null,
      },
      {
        key: "6114901de4b0f2065563fb15",
        location: "36.663976,117.006009",
        name: "测试123",
        owner: "st",
      },
      {
        key: "6110e606e4b09a3b70b1b40f",
        location: "36.684310,116.977187",
        name: "888",
        owner: "sf",
      },
      {
        key: "610a1e77e4b0ad7a66ef010f",
        location: "36.634065,116.957209",
        name: "王官庄八区快递超市",
        owner: null,
      },
      {
        key: "610a1debe4b0ad7a66ef010e",
        location: "36.628118,116.956467",
        name: "贵都花园（鑫鑫超市）",
        owner: null,
      },
      {
        key: "610a1d60e4b0ad7a66ef010d",
        location: "36.642362,116.937213",
        name: "东红庙北路兔喜快递超市",
        owner: null,
      },
      {
        key: "610a1d39e4b0ad7a66ef010c",
        location: "36.638349,116.936762",
        name: "红园北区韵达快递驿站",
        owner: null,
      },
      {
        key: "610a1ce0e4b0ad7a66ef010b",
        location: "36.633895,116.934666",
        name: "红园南区沐风超市驿站",
        owner: null,
      },
      {
        key: "610a01f7e4b0ad7a66ef010a",
        location: "36.656772,117.083929",
        name: "友谊苑南区",
        owner: null,
      },
      {
        key: "6109fac2e4b0ad7a66ef0109",
        location: "36.666548,117.11049",
        name: "济南历下龙湖天璞店",
        owner: null,
      },
      {
        key: "610194fce4b0ad7a433dde70",
        location: "36.659915,117.087026",
        name: "华丰居",
        owner: null,
      },
      {
        key: "610193afe4b0ad7a433dde6f",
        location: "36.66367,117.092767",
        name: "水泥厂宿舍门头房",
        owner: null,
      },
      {
        key: "610192dce4b0ad7a433dde6e",
        location: "36.657013,117.089941",
        name: "姚家小区东区",
        owner: null,
      },
      {
        key: "6101918fe4b0ad7a433dde6d",
        location: "36.662427,117.086978",
        name: "济柴九宿舍门岗超市",
        owner: null,
      },
      {
        key: "6101913ee4b0ad7a433dde6c",
        location: "36.654514,117.079922",
        name: "窑头小区南区妈妈驿站",
        owner: null,
      },
    ];
  },
  vehicleCountStatistic(search) {
    const params = new URLSearchParams(search);
    const startTime = params.get("startTime");
    const endTime = params.get("endTime");
    const startDay = dayjs(startTime);
    const days = dayjs(endTime).diff(startTime, "days");
    const vehicleTotalCount = parseInt(totalDays / 7) + 90;
    const data = [];

    for (let d = 0; d <= days; d++) {
      const date = startDay.add(d, "days").format("YYYY-MM-DD");
      const stopped = parseInt(Math.random() * 5);
      const breakRule = Math.random() < 0.1 ? parseInt(Math.random() * 5) : 0;

      data.push({
        date,
        total: vehicleTotalCount,
        running: vehicleTotalCount - stopped - breakRule,
        stopped,
        breakRule,
      });
    }

    return data;
  },
  mileageTrend(search) {
    const params = new URLSearchParams(search);
    const startTime = params.get("startTime");
    const endTime = params.get("endTime");
    const today = dayjs();
    const hours = today.hour();
    const startDay = dayjs(startTime);
    const days = dayjs(endTime).diff(startTime, "days");
    const data = [];

    for (let d = 0; d <= days; d++) {
      const statDate = startDay.add(d, "days").format("YYYY-MM-DD");
      const locData = mileageTrendLoc.get(statDate);
      let driveMiles =
        (80 + (Math.random() < 0.5 ? 1 : -1) * parseInt(Math.random() * 20)) *
        2;

      if (d === days) {
        driveMiles = parseInt((hours / 24) * 80) * 2;
      }

      if (!locData) {
        const d = {
          statDate,
          driveMiles,
          totalMiles: 0,
        };
        mileageTrendLoc.set(statDate, d);
        data.push(d);
      } else {
        data.push(locData);
      }
    }

    return data;
  },
  distanceSegment() {
    return [
      { range: [0, 40], value: 9 },
      { range: [40, 60], value: 24 },
      { range: [60, 80], value: 46 },
      { range: [80, 10000], value: 21 },
    ];
  },
  timeQuantum() {
    return [
      { range: [0, 3600], value: 5 },
      { range: [3600, 7200], value: 12 },
      { range: [7200, 10800], value: 23 },
      { range: [10800, 14400], value: 36 },
      { range: [14400, 86400], value: 24 },
    ];
  },
  distanceRanking(search) {
    const params = new URLSearchParams(search);
    const size = params.get("size") || 10;
    const today = dayjs();
    const hours = today.hour();

    const data = [];

    for (let i = 0; i < size; i++) {
      if (!rankingLoc[i]) {
        const avg = 40 + ((size - i) / size) * 40;
        const totalMiles = parseInt(totalDays * avg + (hours / 24) * avg);
        const strI = i < 10 ? `0${i}` : `${i}`;

        rankingLoc[i] = {
          vehicleId: `611251d1248f7a923dd46d${strI}`,
          numPlate: getNumPlate(),
          cover: "https://juzhenimage.oss-cn-shanghai.aliyuncs.com/img/ac7.png",
          driveMiles: null,
          totalMiles,
        };
      }

      data.push(rankingLoc[i]);
    }

    return data;
  },
};
