import Vue from "vue";
// import axios from "axios";
// import Cookies from 'js-cookie'
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Detail from "../views/Detail.vue";
import YunTong from "../views/YunTong.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta:{
      title:"登录"
    },
    component: Login,
  },
  {
    path: "/",
    name: "index",
    meta:{
      title:"首页"
    },
    component: Home,
  },
  {
    path: "/yuntong",
    name: "yuntong",
    meta:{
      title:"运通详情"
    },
    component: YunTong,
  },
  {
    path: "/detail",
    name: "detail",
    meta:{
      title:"详情"
    },
    component: Detail,
  },
];

const { pathname } = window.location
const base = pathname
  .replace(/\/(detail|yuntong|login)/, '')
  .replace(/(\/\S+)\/\S*/, '$1');

const router = new VueRouter({
  mode: "history",
  base,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // const token = Cookies.get('token');
  // const verify = async () => {
  //   if (!token) return false;

  //   const verifyRes = await axios({
  //     url: '/verify',
  //     method: 'get',
  //     params: { token }
  //   });

  //   if (verifyRes && verifyRes.data === true) {
  //     return true;
  //   } else {
  //     Cookies.set('token', '');

  //     return false;
  //   }
  // }

  // const params = {
  //   async login() {
  //     const effective = await verify();

  //     return effective ? { path: '/' } : undefined
  //   },
  //   yuntong() {
  //     return undefined;
  //   },
  //   async default() {
  //     const effective = await verify();

  //     return effective ? undefined : {path: '/login'};
  //   }
  // }
  // const param = await (params[to.name] || params.default)();

  if (to.meta.title) {
    document.title = `${to.meta.title}-橙仕大脑`;
  }

  // next(param);
  next();
});

export default router;
