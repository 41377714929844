import Vue from "vue";
import axios from "axios";
import AmapVue from '@amap/amap-vue';

import App from "./App.vue";
import router from "./router";
import "@/assets/css/reset.scss";
import '@/components' // 注册自定义组件

// 环境配置
axios.defaults.baseURL = process.env.yarn || '';

// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 请求拦截器
// axios.interceptors.request.use(
//   config => {
//     // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
//     // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
//     const token = store.state.token;
//     token && (config.headers.Authorization = token);
//     return config;
//   },
//   error => {
//       return Promise.error(error);
//   }
// )

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    // 服务器状态码不是200的情况
    (error) => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                case 401:
                case 403:
                    break;
                // 404请求不存在
                case 404:
                    console.log("网络请求不存在");
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    console.log('response error:', JSON.stringify(error.response.data));
            }
            return Promise.reject(error.response);
        }
    }
);

AmapVue.config.key = '63ab742b2b71e71ffde4388c892e7ba5';
AmapVue.config.plugins = [
  'AMap.Driving',
  'AMap.Marker',
  'AMap.Polyline',
  'AMap.MoveAnimation',
];

Vue.filter('dateFormat', (dateString = '') => {
    if (!dateString) return '';
    
    return dateString.replace(/^(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
});
Vue.filter('formatNumber', (num) => {
    let x = (num || 0).toFixed(0);
    const rgx = /(\d+)(\d{3})/;

    while (rgx.test(x)) {
        x = x.replace(rgx, '$1,$2');
    }

    return x;
});
Vue.use(AmapVue);

Vue.config.productionTip = false;
Vue.prototype.axios = axios;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#root");