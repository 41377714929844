<template>
  <div class="loginPage">
    <div class="login">
      <h1>欢迎来到橙仕大脑</h1>
      <form class="form" @submit.prevent="submit">
        <p class="msg" v-if="errMsg">{{errMsg}}</p>
        <p class="item">
          <label class="label">用户名</label>
          <input class="input" v-model="username" type="text" placeholder="请输入账号用户名" />
        </p>
        <p class="item">
          <label class="label">密码</label>
          <input class="input" v-model="password" type="password" placeholder="请输入账号密码" />
        </p>
        <p class="item">
          <button class="btn" :disabled="canSubmit" type="submit">登 录</button>
        </p>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.loginPage {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .login {
    padding: 24px 36px;
    width: 500px;
    font-size: 16px;
    line-height: 2;
    color: #8e94cc;
    border-radius: 10px;
    background: #2c315d;

    h1 {
      font-size: 32px;
      color: #fff;
      text-align: center;
      line-height: 2;
    }

    .form {
      display: block;
      margin: 12px auto;
      max-width: 80%;

      .item {
        display: flex;
        flex-direction: column;

        & + .item {
          margin-top: 12px;
        }

        &:last-child {
          padding: 12px 0;
        }
      }

      .msg {
        color: #f00;
        text-align: center;
      }

      .input, .btn {
        padding: 8px 12px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 4px;
        border: none;
        outline: none;
        font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
      }

      .btn {
        color: #1f2345;
        background: #00f6e5;
        cursor: pointer;
        border-radius: 40px;

        &:hover {
          opacity: .8;
        }

        &:disabled {
          opacity: .3;
        }
      }
    }
  }
}
</style>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      username: '',
      password: '',
      submiting: false,
      errMsg: '',
    }
  },
  computed: {
    canSubmit() {
      const { username, password, submiting } = this;

      return submiting || !(username && password);
    }
  },
  methods: {
    submit() {
      const { username, password } = this;

      this.submiting = true;

      this.axios({
        url: '/login',
        method: 'post',
        data: {
          username, password
        }
      }).then((res) => {
        const { code, data, msg } = res;

        if (code === 0) {
          Cookies.set('token', data);

          this.$router.push('/');
        } else {
          this.errMsg = msg;
        }

        this.submiting = false;
      }).catch((err) => {
        this.errMsg = err.message;
        this.submiting = false;
      });
    }
  }
}
</script>