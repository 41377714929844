<template>
  <div :class="$style.page">
    <div :class="$style.pageExtra">
      <p :class="$style.updateTime">更新时间：{{ updatetime }}</p>
      <span :class="$style.fullScreen" @click="fullScreen">
        <svg
          v-if="isFullScreen"
          :class="$style.svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.292 8.255c.017 0 .034.002.051.004l.013.003.024.003a.57.57 0 0 1 .064.017l.006.004a.55.55 0 0 1 .232.145c.106.11.164.259.161.413v3.493c0 .322-.26.584-.582.584H5.26a.583.583 0 0 1-.582-.584v-2.118l-3.69 3.603a.57.57 0 0 1-.971-.267.585.585 0 0 1 .171-.565L3.842 9.42H1.76a.583.583 0 0 1 0-1.167h3.533zm2.89.355a.72.72 0 0 1 .033-.1l.01-.02a.58.58 0 0 1 .443-.318l.026-.002a.416.416 0 0 1 .054-.003h3.478c.32 0 .58.257.58.574v.001c0 .317-.26.574-.58.574l-2.079.001 3.673 3.698c.155.145.216.36.16.562a.582.582 0 0 1-.431.407.6.6 0 0 1-.575-.171l-3.64-3.665v2.104a.583.583 0 0 1-1.167-.001V8.708l.004-.034v-.01.007l.01-.061zM1.01.187l3.57 3.655V1.76a.582.582 0 0 1 .582-.582.584.584 0 0 1 .584.582v3.523l-.002.034v-.035l-.004.058-.005.032a.346.346 0 0 1-.01.052l-.009.022a.558.558 0 0 1-.147.236l-.03.027a.578.578 0 0 1-.198.109l-.031.009a.474.474 0 0 1-.11.016l-.016.001H1.708a.579.579 0 0 1-.578-.58v-.001c0-.32.259-.58.578-.58h2.079L.177.987A.57.57 0 0 1 .446.016a.585.585 0 0 1 .565.171zM13.574.02a.579.579 0 0 1 .237.989l-3.777 3.658h2.167a.583.583 0 0 1 0 1.166H8.69a.577.577 0 0 1-.575-.58V1.773c0-.154.06-.302.168-.41a.57.57 0 0 1 .406-.17h.002a.57.57 0 0 1 .406.17.584.584 0 0 1 .168.41V3.8l3.74-3.623a.576.576 0 0 1 .568-.157z"
            fill="#FEFEFF"
            fill-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          :class="$style.svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.42 8.275a.585.585 0 0 1 .236.99l-3.655 3.568h2.083a.583.583 0 0 1 0 1.167H.552a.573.573 0 0 1-.052-.005l-.012-.003-.025-.003a.57.57 0 0 1-.063-.016l-.007-.004a.55.55 0 0 1-.231-.146.582.582 0 0 1-.162-.412V9.918c0-.323.26-.585.582-.585h.002c.322 0 .583.262.583.585l-.001 2.117 3.69-3.603a.57.57 0 0 1 .564-.157zm3.772.079l3.64 3.665.001-2.104a.583.583 0 0 1 1.167 0v3.543l-.003.022v.013a.566.566 0 0 1-.045.163l-.011.021a.58.58 0 0 1-.442.318l-.027.002-.026.002L9.941 14a.577.577 0 0 1-.581-.574v-.002c0-.317.26-.574.58-.574h2.08L8.347 9.152a.562.562 0 0 1-.16-.563.582.582 0 0 1 .431-.406.6.6 0 0 1 .574.17zM4.038 0c.319 0 .578.26.578.58v.002c0 .32-.259.58-.578.58L1.959 1.16l3.61 3.695a.57.57 0 0 1-.268.971.585.585 0 0 1-.566-.171L1.166 2.001v2.084a.582.582 0 0 1-.583.582A.584.584 0 0 1 0 4.085V.562C0 .539.003.516.006.493L.004.514.003.526 0 .561.004.514l.01-.068A.432.432 0 0 1 .022.42L.03.398A.558.558 0 0 1 .177.162l.03-.026a.578.578 0 0 1 .198-.11L.436.018A.474.474 0 0 1 .49.007L.546 0 4.038 0zm9.388 0c.317 0 .574.26.574.58v3.48c0 .153-.06.3-.168.41a.57.57 0 0 1-.406.17h-.002a.57.57 0 0 1-.405-.17.584.584 0 0 1-.169-.41V2.032L9.11 5.656a.576.576 0 0 1-.977-.267.581.581 0 0 1 .172-.564l3.777-3.659H9.916a.583.583 0 0 1 0-1.166h3.51z"
            fill="#FEFEFF"
            fill-rule="evenodd"
          />
        </svg>
      </span>
    </div>

    <div :class="$style.side">
      <h1 :class="$style.pageTitle">橙仕大脑</h1>

      <!-- 数据总览 -->
      <div :class="$style.dataScreen">
        <div :class="$style.main">
          <ul :class="$style.data">
            <li>
              <strong>{{
                dataScreenShow.totalProtectTimes | formatNumber
              }}</strong>
              累计守护次数
            </li>
            <li>
              <strong>195</strong>
              今日守护次数
            </li>
          </ul>

          <svg
            :class="$style.svgIcon"
            width="128"
            height="198"
            viewBox="0 0 128 198"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="15.278%"
                y1="0%"
                x2="84.722%"
                y2="100%"
                id="app_a"
              >
                <stop stop-color="#2DC9EB" offset="0%" />
                <stop stop-color="#14D2B8" offset="100%" />
              </linearGradient>
              <linearGradient x1="50%" y1=".953%" x2="50%" y2="100%" id="app_b">
                <stop stop-color="#1F2345" stop-opacity="0" offset="0%" />
                <stop stop-color="#00F6E5" offset="56.829%" />
                <stop stop-color="#1F2345" stop-opacity="0" offset="100%" />
              </linearGradient>
              <linearGradient x1="97.04%" y1="50%" x2="0%" y2="50%" id="app_c">
                <stop stop-color="#FFF" offset="0%" />
                <stop stop-color="#ECE9E9" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="100%"
                y1="39.968%"
                x2="0%"
                y2="39.968%"
                id="app_d"
              >
                <stop stop-color="#313827" offset="0%" />
                <stop stop-color="#10130C" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fill-rule="evenodd">
              <path
                d="M76.124 66.862l-.08-.028-11.667-2.79a1.672 1.672 0 0 0-.343-.044h-.068c-.114 0-.228.016-.343.044l-11.668 2.79-.079.028c-1.72.612-2.876 2.395-2.876 4.44v13.655c0 2.399.817 4.706 2.427 6.851 1.223 1.626 2.908 3.164 5.003 4.565a33.49 33.49 0 0 0 4.845 2.649c.549.245 1.086.467 1.598.664.652.25.825.314 1.145.314.248 0 .489-.06.702-.165.845-.306 3.831-1.45 6.85-3.466 2.1-1.4 3.78-2.935 5.003-4.565C78.183 89.66 79 87.356 79 84.953v-13.65c0-2.046-1.156-3.829-2.876-4.44z"
                fill="url(#app_a)"
                :class="`${$style.svgGuard} ${$style.c2}`"
              />
              <path
                d="M76.124 66.862l-.08-.028-11.667-2.79a1.672 1.672 0 0 0-.343-.044h-.068c-.114 0-.228.016-.343.044l-11.668 2.79-.079.028c-1.72.612-2.876 2.395-2.876 4.44v13.655c0 2.399.817 4.706 2.427 6.851 1.223 1.626 2.908 3.164 5.003 4.565a33.49 33.49 0 0 0 4.845 2.649c.549.245 1.086.467 1.598.664.652.25.825.314 1.145.314.248 0 .489-.06.702-.165.845-.306 3.831-1.45 6.85-3.466 2.1-1.4 3.78-2.935 5.003-4.565C78.183 89.66 79 87.356 79 84.953v-13.65c0-2.046-1.156-3.829-2.876-4.44z"
                fill="url(#app_a)"
                :class="`${$style.svgGuard} ${$style.c1}`"
              />
              <path
                d="M76.124 66.862l-.08-.028-11.667-2.79a1.672 1.672 0 0 0-.343-.044h-.068c-.114 0-.228.016-.343.044l-11.668 2.79-.079.028c-1.72.612-2.876 2.395-2.876 4.44v13.655c0 2.399.817 4.706 2.427 6.851 1.223 1.626 2.908 3.164 5.003 4.565a33.49 33.49 0 0 0 4.845 2.649c.549.245 1.086.467 1.598.664.652.25.825.314 1.145.314.248 0 .489-.06.702-.165.845-.306 3.831-1.45 6.85-3.466 2.1-1.4 3.78-2.935 5.003-4.565C78.183 89.66 79 87.356 79 84.953v-13.65c0-2.046-1.156-3.829-2.876-4.44z"
                fill="url(#app_a)"
                :class="$style.svgGuard"
              />
              <g fill="url(#app_b)">
                <path
                  d="M127.869 0H118l.131 198H128zM9.869 0H0l.131 198H10z"
                  :class="$style.translateY"
                />
              </g>
              <g transform="rotate(-90 100 56)">
                <path
                  d="M62 0c1.306 0 2.418.835 2.83 2H74a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6h-9.171A3.001 3.001 0 0 1 62 40h-1.5a3.001 3.001 0 0 1-2.829-2H6a6 6 0 0 1-6-6V8a6 6 0 0 1 6-6h51.67a3.001 3.001 0 0 1 2.83-2H62z"
                  fill="url(#app_c)"
                />
                <rect
                  stroke="#979797"
                  stroke-width=".25"
                  fill="#CBCBCB"
                  opacity=".253"
                  x=".125"
                  y="6.125"
                  width="67.25"
                  height="27.75"
                  rx="3"
                />
                <rect
                  fill="#1F2418"
                  x="11.034"
                  y="4.138"
                  width="16.552"
                  height="1.379"
                  rx=".5"
                />
                <path
                  d="M58.606 6h15.522a1 1 0 0 1 .991.867c.715 5.31 1.072 10.024 1.072 14.138 0 4.06-.348 8.114-1.043 12.164a1 1 0 0 1-.986.831H58.615a1 1 0 0 1-.995-1.103c.419-4.034.628-8.234.628-12.6 0-4.4-.212-8.8-.638-13.2A1 1 0 0 1 58.606 6z"
                  fill="url(#app_d)"
                />
              </g>
            </g>
          </svg>
        </div>

        <ul :class="$style.secondary">
          <li :class="$style.distance">
            <strong
              >{{ dataScreenShow.totalDistance | formatNumber
              }}<span :class="$style.unit">km</span></strong
            >
            累计里程
          </li>
          <li :class="$style.emissions">
            <strong
              >{{ dataScreenShow.emissions | formatNumber
              }}<span :class="$style.unit">kg</span></strong
            >
            碳排量减少
          </li>
          <li :class="$style.trees">
            <strong
              >{{ dataScreenShow.trees | formatNumber
              }}<span :class="$style.unit">棵</span></strong
            >
            相当于植树
          </li>
        </ul>
      </div>

      <div class="scrollbar" :class="$style.chartWrap">
        <template v-for="(config, key) in chartConfig">
          <div
            class="boxGroup"
            v-if="config.key === 'ChartBoxGroup'"
            :key="key"
          >
            <chart-box
              v-for="(subConfig, index) in config.vData"
              :key="index"
              :title="subConfig.title"
              :trigger="subConfig.trigger"
              @enlarge="(ev) => enlarge(ev, subConfig)"
            >
              <component :is="subConfig.key" :vData="subConfig.vData" />
            </chart-box>
          </div>
          <chart-box
            v-else
            :key="key"
            :title="config.title"
            :trigger="config.trigger"
            @enlarge="(ev) => enlarge(ev, config)"
          >
            <component :is="config.key" :vData="config.vData" />
          </chart-box>
        </template>
      </div>
    </div>

    <div :class="$style.mapBox">
      <div :class="$style.chart">
        <amap
          ref="aMap"
          map-style="amap://styles/161416970c7c92f52d88092c7b514b01"
          :zoom="10"
          @complete="mapComplete"
          @click="mapClick"
        >
          <!-- @click="markerClick"
          @moving="markerMoving"
          @moveend="markerMoveend" -->
          <amap-marker
            v-for="marker in map.markers"
            :key="marker.vehicleId"
            :position="marker.position"
            :offset="marker.offset"
            :content="
              getMarkerContent(
                marker.vehicleId === map.curVehicleId
                  ? 'actived'
                  : marker.status,
                marker.vehicleId
              )
            "
            :extraOptions="{ extData: { vehicleId: marker.vehicleId } }"
          />

          <amap-info-window
            v-if="map.carIndex >= 0 && !!map.carList[map.carIndex]"
            :position="map.winPosition"
            :visible="true"
            :offset="[-36, -140]"
            anchor="top-left"
            is-custom
            ><transition>
              <div :class="$style.carBox" :key="map.curVehicleId">
                <p :class="$style.carSummary">
                  <span :class="$style.txt">区域车辆数</span>
                  <strong>{{ map.carList.length }}</strong>
                </p>
                <div :class="$style.car">
                  <router-link
                    :to="
                      `./detail?vehicleId=${
                        map.carList[map.carIndex].vehicleId
                      }`
                    "
                    :key="map.carIndex"
                  >
                    <div :class="$style.hd">
                      <h5>{{ map.carList[map.carIndex].numPlate }}</h5>
                      <p :class="$style.extra">
                        今日已行驶{{
                          parseInt(map.carList[map.carIndex].driveMiles) || 0
                        }}KM
                      </p>
                      <svg
                        :class="$style.icon"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.379 12l-7.94 7.56a1.385 1.385 0 0 0 0 2.023 1.56 1.56 0 0 0 2.123 0l9-8.57a1.386 1.386 0 0 0 .375-1.419c-.013-.045-.03-.088-.046-.132a1.41 1.41 0 0 0-.328-.472L8.562 2.417a1.56 1.56 0 0 0-2.124 0 1.385 1.385 0 0 0 0 2.022L14.378 12z"
                          fill="#FFFFFF"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </div>
                    <div :class="$style.bd">
                      <span :class="$style.power">
                        <svg
                          :class="$style.icon"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g stroke="#FFF" fill="none" fill-rule="evenodd">
                            <path
                              d="M13 3.5l.5 2h1v5h-1V12L2 12.5 1.5 4 13 3.5zM5.5 6v4M9.5 6v4"
                            />
                          </g>
                        </svg>
                        {{ parseInt(map.carList[map.carIndex].power) || 0 }}%
                      </span>
                      <span :class="$style.mileage">
                        <svg
                          :class="$style.icon"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <circle stroke="#FFF" cx="8" cy="8" r="5.5" />
                            <path
                              d="M7.908 6c-.56 1.419-.86 2.348-.901 2.788-.062.66.301 1.176.925 1.142.623-.035.816-.39.83-1.163C8.77 8.25 8.486 7.329 7.908 6z"
                              fill="#FFF"
                            />
                          </g>
                        </svg>
                        {{ parseInt(map.carList[map.carIndex].speed) || 0 }}km/h
                      </span>
                    </div>
                  </router-link>
                </div>
                <div :class="$style.carNav" v-if="map.carList.length > 1">
                  <span
                    :class="{
                      [$style.prev]: true,
                      [$style.disabled]: map.carIndex === 0,
                    }"
                    @click="carMarkerPrev"
                  >
                    <svg
                      :class="$style.icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.621 12l7.94 7.56a1.385 1.385 0 0 1 0 2.023 1.56 1.56 0 0 1-2.123 0l-9-8.57a1.386 1.386 0 0 1-.375-1.419c.013-.045.03-.088.046-.132a1.41 1.41 0 0 1 .328-.472l9.001-8.573a1.56 1.56 0 0 1 2.124 0 1.385 1.385 0 0 1 0 2.022L9.622 12z"
                        fill="currentColor"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </span>
                  <span
                    :class="{
                      [$style.next]: true,
                      [$style.disabled]: map.carIndex >= map.carList.length - 1,
                    }"
                    @click="carMarkerNext"
                  >
                    <svg
                      :class="$style.icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.379 12l-7.94 7.56a1.385 1.385 0 0 0 0 2.023 1.56 1.56 0 0 0 2.123 0l9-8.57a1.386 1.386 0 0 0 .375-1.419c-.013-.045-.03-.088-.046-.132a1.41 1.41 0 0 0-.328-.472L8.562 2.417a1.56 1.56 0 0 0-2.124 0 1.385 1.385 0 0 0 0 2.022L14.378 12z"
                        fill="currentColor"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </span>
                </div>
              </div> </transition
          ></amap-info-window>
        </amap>
      </div>

      <!-- <div :class="$style.avgSpeed">
        <svg
          :class="$style.svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zM2.91 8h-.728a5.818 5.818 0 0 1 11.636 0h-.727A5.09 5.09 0 1 0 2.909 8zm6.744-3.457L9.38 10.15l-1.03 1.784-.359-2.03 1.662-5.361z"
            fill="#5F659B"
            fill-rule="evenodd"
          />
        </svg>
        <h4>平均时速</h4>
        <span :class="$style.speed">{{ parseInt(avgSpeed) || 0 }}km/h</span>
      </div> -->

      <div :class="$style.points">
        <h4>网点分布</h4>
        <div :class="$style.chart">
          <amap
            ref="expressPoint"
            map-style="amap://styles/161416970c7c92f52d88092c7b514b01"
            :zoom="12"
            :zoom-enable="false"
            :drag-enable="false"
            :touch-zoom="false"
            :center="expressPointCenter"
            @complete="aMapComplete"
          >
            <amap-circle-marker
              v-for="(item, index) in expressPoints"
              :key="index"
              :center="item.center"
              :radius="0.125 * remUnit"
              strokeColor="#ffffff"
              :strokeOpacity="0.02"
              :strokeWeight="3"
              fillColor="#00DF43"
              :fillOpacity="0.5"
              cursor="pointer"
              :clickable="false"
              :bubble="false"
              :zIndex="10 + index"
              :extraOptions="{ extData: { id: item.id } }"
            />
          </amap>
        </div>
      </div>
    </div>

    <transition name="modal">
      <div :class="$style.chartBoxLarge" v-if="!!lgConfig">
        <header :class="$style.hd" v-if="lgConfig.title !== 'noHd'">
          <h3 v-if="lgConfig.title" :class="$style.title">
            {{ lgConfig.title }}
          </h3>
          <ul v-if="!!lgConfig.tabs" :class="$style.tab">
            <li
              v-for="(tab, index) in lgConfig.tabs"
              :key="index"
              :class="{ [$style.current]: lgConfig.current === index }"
              @click="changeTab(lgConfig, index)"
            >
              {{ tab }}
            </li>
          </ul>
          <span :class="$style.close" @click="dislarge"></span>
        </header>
        <div :class="$style.bd">
          <component :is="lgConfig.key" :vData="lgConfig.vData" :large="true" />
        </div>
      </div>
    </transition>
  </div>
</template>

<style module lang="scss">
// 所有单位按1rem = 20px换算
.pageExtra {
  position: absolute;
  top: 1rem;
  right: 1.4rem;
  display: flex;
  z-index: 9;

  .updateTime {
    margin-right: 2.7rem;
    color: #a5a9d1;
    font-size: 0.7rem;
    line-height: 1.5rem;
  }

  .fullScreen {
    height: 1.5rem;
    padding: 0.4rem 0.8rem;
    border-radius: 1.5rem;
    background-color: #2c315d;

    .svg {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
}

.page {
  position: relative;
  display: flex;
  padding-left: 12.7rem;
  line-height: 1.5;
  color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chartBoxLarge {
  position: absolute;
  top: 50%;
  left: 24rem;
  margin: -10rem 0 0 0;
  padding: 1rem 2rem;
  width: 40rem;
  height: 20rem;
  background: rgba($color: #1f2345, $alpha: 0.85);
  border: 1px solid rgba($color: #00fbe5, $alpha: 0.85);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  z-index: 99;

  .hd {
    display: flex;
    line-height: 2.5rem;
    align-items: center;

    .title {
      font-size: 0.8rem;
    }

    .tab {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-left: 1.2rem;

      li {
        padding: 0 0.5rem;
        font-size: 0.7rem;
        line-height: 1.3rem;
        color: #a5a9d1;
        cursor: pointer;
        transition: color 0.3s;

        &.current {
          font-size: 0.8rem;
          color: #fff;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 100%;
    left: 50%;
    margin: 1rem 0 0 -1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba($color: #1f2345, $alpha: 0.85);
    border: 1px solid rgba($color: #00fbe5, $alpha: 0.85);
    border-radius: 50%;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.05rem 0 0 -0.65rem;
      width: 1.3rem;
      height: 0.1rem;
      border-radius: 0.1rem;
      background-color: #00f6e5;
      transform: rotate(45deg);
      transition: transform 0.3s;
      content: "";
    }

    &::after {
      margin: -0.65rem 0 0 -0.05rem;
      width: 0.1rem;
      height: 1.3rem;
    }

    &:active,
    &:hover {
      &::before,
      &::after {
        transform: rotate(135deg);
      }
    }
  }
}

.mapBox {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;

  .chart {
    flex: 1;
  }
}

.side {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 2;
  padding: 1.2rem 0 0 1.2rem;
  width: 19.2rem;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    content: "";
    z-index: -1;
    pointer-events: none;
    background-image: linear-gradient(
      90deg,
      #1f2345 36%,
      rgba(31, 35, 69, 0) 100%
    );
  }
}

.pageTitle {
  padding-left: 1rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.dataScreen {
  margin: 1rem 0 0.6rem;
  padding-left: 1rem;
  color: #a5a9d1;

  .main {
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: space-between;

    .data {
      flex: 1;

      li {
        margin-top: 0.6rem;

        strong {
          display: block;
          color: #fff;
          font-size: 1.7rem;
          line-height: 2.3rem;
        }
      }
    }

    .svgIcon {
      width: 6.4rem;
      height: 9.9rem;
    }
  }

  .secondary {
    display: flex;

    li {
      flex: 1.4;

      &:last-child {
        flex: 1;
      }

      strong {
        display: block;
        color: #fff;
        font-size: 0.8rem;
        line-height: 1.1rem;

        .unit {
          padding-left: 0.2rem;
          font-size: 0.7rem;
          font-weight: normal;
        }
      }
    }
  }
}
.chartWrap {
  flex: 1;
  overflow: auto;
}

.avgSpeed {
  position: absolute;
  right: 1.3rem;
  display: flex;
  align-items: center;
  bottom: 9.5rem;
  width: 10rem;
  height: 2.2rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  color: #ffffff;
  border-radius: 0.4rem;
  background-color: #2c315d;
  border: 1px solid #343b74;
  z-index: 2000;

  .svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  h4 {
    margin: 0 0.9rem 0 0.3rem;
    font-size: 0.7rem;
    color: #a5a9d1;
  }
}

.points {
  position: absolute;
  right: 1.3rem;
  bottom: 1.9rem;
  width: 10rem;
  height: 7rem;
  background-color: #2c315d;
  border: 1px solid #5f659b;
  border-radius: 0.4rem;
  z-index: 2000;

  h4 {
    position: absolute;
    top: 0.4rem;
    left: 1.3rem;
    font-size: 0.8rem;
    line-height: 1.1rem;
    color: #ffffff;
    z-index: 2;
  }

  .chart {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    overflow: hidden;
    z-index: 1;
  }
}

.carBox {
  display: flex;
  height: 100px;
  color: #fff;
  overflow: hidden;

  .carSummary,
  .car,
  .carNav {
    transform-origin: 50% 0%;
    animation: carBoxIn 0.12s both;
  }
  .carSummary > strong,
  .carSummary > span,
  .car .hd h5,
  .car .hd .extra,
  .car .bd,
  .carNav .prev,
  .carNav .next {
    white-space: nowrap;
    overflow: hidden;
    animation: typing 0.12s both;
    animation-delay: 0.12s;
  }
  .carSummary > span {
    animation-delay: 0.24s;
  }
  .car .hd h5 {
    animation-delay: 0.24s;
  }
  .car .hd .extra {
    animation-delay: 0.36s;
  }
  .car .bd {
    animation-delay: 0.48s;
  }
  .carNav .prev {
    animation-delay: 0.6s;
  }
  .carNav .next {
    animation-delay: 0.72s;
  }

  .carSummary {
    display: flex;
    margin-right: 12px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 10px;
    width: 116px;
    font-size: 16px;
    line-height: 24px;
    background: rgba(31, 35, 69, 0.85);
    border: 1px solid #00fbe5;
    border-radius: 8px;

    strong {
      font-size: 32px;
      line-height: 44px;
    }
  }

  .car {
    margin-right: 12px;
    width: 220px;
    padding: 10px 12px;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    background: rgba(31, 35, 69, 0.85);
    border: 1px solid #00fbe5;
    border-radius: 8px;
    animation-delay: 0.16s;

    a,
    a:visited {
      color: #fff;
    }

    .hd {
      position: relative;
      margin-bottom: 12px;

      h5 {
        font-size: 20px;
        line-height: 24px;
      }

      .icon {
        position: absolute;
        top: 6px;
        right: 0;
        width: 12px;
        height: 12px;
      }
    }

    .bd {
      display: flex;
      color: #00f6e5;
      line-height: 20px;

      .icon {
        margin-top: 2px;
        vertical-align: top;
      }

      .power,
      .mileage {
        white-space: nowrap;
      }

      .power {
        margin-right: 18px;
      }
    }
  }

  .carNav {
    display: flex;
    flex-direction: column;
    width: 46px;
    animation-delay: 0.32s;

    .prev,
    .next {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(31, 35, 69, 0.85);
      border: 1px solid #00fbe5;
      border-radius: 8px;
      transition: all 0.3s;
    }

    .next {
      margin-top: 8px;
    }

    .disabled {
      color: #92a1a4;
      border-color: #92a1a4;
    }
  }
}

.carMarker {
  position: relative;
  padding: 2px;
  width: 32px;
  height: 32px;

  :global(.icon) {
    position: relative;
    display: block;
    margin: 0 auto;
    z-index: 4;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &:global(.actived) {
    i {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::before,
    &::after,
    i::before,
    i::after {
      position: absolute;
      width: 14px;
      height: 14px;
      content: "";
      border: 1px solid #00f6e5;
    }

    &::before {
      top: 0;
      left: 0;
      border-color: #00f6e5 transparent transparent #00f6e5;
    }
    &::after {
      top: 0;
      right: 0;
      border-color: #00f6e5 #00f6e5 transparent transparent;
    }
    i::before {
      bottom: 0;
      left: 0;
      border-color: transparent transparent #00f6e5 #00f6e5;
    }
    i::after {
      bottom: 0;
      right: 0;
      border-color: transparent #00f6e5 #00f6e5 transparent;
    }
  }

  &:global(.charging) {
    i {
      margin: -7px 0 0 -7px;
      width: 14px;
      height: 14px;
      animation: charging 1s infinite both;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii42NTMiPjxjaXJjbGUgZmlsbD0iIzYxQkY1QSIgY3g9IjciIGN5PSI3IiByPSI3Ii8+PHBhdGggZD0iTTUuODA4IDcuNzY1aC0xLjc2YS4zMjguMzI4IDAgMCAxLS4zMDctLjQ0NWwxLjc1LTQuNTkzYS4zMjguMzI4IDAgMCAxIC4zMDYtLjIxMUg4Ljc1Yy4yMzMgMCAuMzkxLjIzNi4zMDMuNDUxTDguMDM1IDUuNDY4aDEuOTE4Yy4yODIgMCAuNDMyLjMzMy4yNDYuNTQ1TDUuNDk3IDExLjM3Yy0uMjI5LjI2LS42NTMuMDM1LS41NjQtLjNsLjg3NS0zLjMwNnoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=)
        no-repeat 50%;
      background-size: contain;
      z-index: 5;
    }
  }

  &:global(.moving) {
    i {
      margin: -9px 0 0 -9px;
      width: 18px;
      height: 18px;
      background-color: #61bf5a;
      border-radius: 50%;
      z-index: 3;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #61bf5a;
        border-radius: 50%;
        animation: expand 3s infinite both;
        z-index: 2;
      }
      &::after {
        animation-delay: 1.5s;
        z-index: 1;
      }
    }
  }
}

.carMarkerst {
  &:global(.actived) {
    &::before {
      border-color: #ff6600 transparent transparent #ff6600;
    }
    &::after {
      border-color: #ff6600 #ff6600 transparent transparent;
    }
    i::before {
      border-color: transparent transparent #ff6600 #ff6600;
    }
    i::after {
      border-color: transparent #ff6600 #ff6600 transparent;
    }
  }

  &:global(.moving) {
    i {
      background-color: #ff6700;

      &::before,
      &::after {
        background-color: #ff6700;
      }
    }
  }
}
.carMarkeryt {
  &:global(.actived) {
    &::before {
      border-color: #5e0393 transparent transparent #5e0393;
    }
    &::after {
      border-color: #5e0393 #5e0393 transparent transparent;
    }
    i::before {
      border-color: transparent transparent #5e0393 #5e0393;
    }
    i::after {
      border-color: transparent #5e0393 #5e0393 transparent;
    }
  }

  &:global(.moving) {
    i {
      background-color: #5e0393;

      &::before,
      &::after {
        background-color: #5e0393;
      }
    }
  }
}

.svgGuard {
  transform-origin: 50% 41%;

  &.c1 {
    animation: expand 3s infinite both;
  }
  &.c2 {
    animation: expand 3s infinite both;
    animation-delay: 1.5s;
  }
}

.translateY {
  animation: translateY 3s infinite both;
}

@keyframes translateY {
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-50%);
  }
  60% {
    opacity: 0.4;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(80%);
  }
}
@keyframes carBoxIn {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes typing {
  0% {
    opacity: 0;
    width: 0;
  }
  10% {
    opacity: 1;
    width: 10%;
  }
  100% {
    width: 100%;
  }
}

@keyframes charging {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes expand {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.32;
    transform: scale(2.5);
  }
  75% {
    opacity: 0.2;
    transform: scale(2.5);
  }
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
</style>

<script>
import dayjs from "dayjs";
import { TweenLite } from "gsap";
import ChartBox from "../components/ChartBox";
import { config } from "../config";
// import mockData from '../demo.json';
import mockDataFn from "../mockData.js";
import { getVehicleBatch } from "@/api/car.js";

// 全局刷新时间，0表示不刷新, 默认10分钟
const interval = 10 * 60 * 1000;
const today = dayjs();
const endTime = today.format("YYYY-MM-DD");
const startTime = today.subtract(6, "days").format("YYYY-MM-DD");
const startTime2 = dayjs()
  .subtract(1, "month")
  .format("YYYY-MM-DD");
const endTime2 = dayjs()
  .subtract(1, "days")
  .format("YYYY-MM-DD");
const statusNums = ["", "moving", "charging"];
const { vehicleIds, markerColors } = config;

const _chartConfig = [
  {
    id: "CarStatistic",
    key: "CarStatistic",
    title: "noHd",
    trigger: false,
    // interval,
    // api: `api/statictis/breakRulesStatistic?startTime=${startTime}&endTime=${endTime}`,
  },
  {
    id: "CarStatus",
    key: "CarStatus",
    title: "车辆运行",
    interval,
    api: `api/statictis/vehicleCountStatistic?startTime=${startTime}&endTime=${endTime}`,
    enlarge: {
      api: `api/statictis/vehicleCountStatistic?startTime=${startTime2}&endTime=${endTime}`,
    },
  },
  // {
  //   id: "VehicleDistribution",
  //   key: "VehicleDistribution",
  //   title: "车辆分布",
  //   interval,
  //   api: `api/statictis/vehicleDistribution`,
  //   render: (res) => {
  //     const { companyList = [] } = res.data;
  //     let max = 0;
  //     const data = companyList.map(({company, total}) => {
  //         max = Math.max(max, total);

  //         return {
  //           label: company,
  //           value: total,
  //         };
  //       })
  //       .sort((item, next) => next.value - item.value);

  //     data.forEach((item) => {
  //       item.max = max;
  //     });

  //     return data;
  //   },
  // },
  // {
  //   key: 'MileageStatistic',
  //   title: '驾驶里程',
  //   interval,
  //   api: [
  //     `api/statictis/mileageTrend?startTime=${startTime}&endTime=${endTime}`,
  //     `api/statictis/daliyMiles`,
  //   ],
  //   render: (res) => {
  //     const [mileageTrend, daliyMiles] = res;

  //     return {
  //       mileageTrends: (mileageTrend.data || []).map((item) => {
  //         const { statDate, driveMiles, totalMiles } = item;

  //         return {
  //           statDate: statDate.replace(/\d{4}(\d{2})(\d{2})/, "$1-$2"),
  //           driveMiles: parseInt(driveMiles) || 0,
  //           totalMiles: parseInt(totalMiles) || 0,
  //         };
  //       }),
  //       daliyMileageTrend: (daliyMiles.data || []).map((item) => {
  //         const { hour, driveMiles, totalMiles } = item;

  //         return {
  //           hour: `${hour}时`,
  //           driveMiles: parseInt(driveMiles) || 0,
  //           totalMiles: parseInt(totalMiles) || 0,
  //         }
  //       })
  //     }
  //   }
  // },
  {
    id: "MileageTrend",
    key: "MileageTrend",
    title: "驾驶里程",
    interval,
    api: `api/statictis/mileageTrend?startTime=${startTime}&endTime=${endTime}`,
    render: (res) => {
      return (res || []).map((item) => {
        const { statDate, driveMiles, totalMiles } = item;

        return {
          statDate: statDate.replace(/\d{4}-(\d{2})-(\d{2})/, "$1-$2"),
          driveMiles: parseInt(driveMiles) || 0,
          totalMiles: parseInt(totalMiles) || 0,
        };
      });
    },
    enlarge: {
      api: `api/statictis/mileageTrend?startTime=${startTime2}&endTime=${endTime}`,
    },
  },
  {
    id: "DistanceSegment",
    key: "DistanceSegment",
    title: "驾驶里程分布",
    interval,
    api: `api/statictis/distanceSegment?startTime=${endTime}&endTime=${endTime}`,
    render: (res) => {
      const total = res.reduce((total, item) => {
        return total + item.value;
      }, 0);

      return res.map((item) => {
        let label = `${item.range.join("-")}km`;

        if (item.range[1] === 10000) {
          label = `${item.range[0]}km+`;
        }

        return {
          label,
          value: item.value,
          percent: parseInt((item.value / total) * 100),
        };
      });
    },
    enlarge: {
      current: 0,
      defaultCurrent: 0,
      tabs: ["今天里程分布", "昨天里程分布", "近7天里程分布"],
      api: [
        `api/statictis/distanceSegment?startTime=${endTime}&endTime=${endTime}`,
        `api/statictis/distanceSegment?startTime=${endTime2}&endTime=${endTime2}`,
        `api/statictis/distanceSegment?startTime=${startTime}&endTime=${endTime}`,
      ],
    },
  },
  {
    id: "TimeQuantum",
    key: "TimeQuantum",
    title: "驾驶时长",
    interval,
    api: `api/statictis/timeQuantum?startTime=${startTime}&endTime=${endTime}`,
    render: (res) => {
      return res.map((item) => {
        const range = item.range.map((r) => ~~r / 60 / 60);
        let label = range.join("-");

        if (range[1] === 24) {
          label = `${range[0]}+`;
        }

        return {
          label,
          value: item.value || 0,
        };
      });
    },
    enlarge: {
      current: 0,
      defaultCurrent: 0,
      tabs: ["近1月", "近7天"],
      api: [
        `api/statictis/timeQuantum?startTime=${startTime2}&endTime=${endTime}`,
        `api/statictis/timeQuantum?startTime=${startTime}&endTime=${endTime}`,
      ],
    },
  },
  // {
  //   id: "ChartBoxGroup_1",
  //   key: "ChartBoxGroup",
  //   vData: [{
  //     id: "SexRatio",
  //     key: "SexRatio",
  //     title: "性别比例",
  //     interval,
  //     trigger: false,
  //     api: `api/courier/sexRatio`,
  //     render: (res) => {
  //       const data = {};
  //       const total = res.data.reduce((total, item) => {
  //         return total + item.value
  //       }, 0);

  //       res.data.forEach((item) => {
  //         const r = item.value / total * 100;

  //         if (item.key) {
  //           data.fSex = r;
  //         } else {
  //           data.mSex = r;
  //         }
  //       })

  //       return data
  //     }
  //   }, {
  //     id: "AgeRatio",
  //     key: "AgeRatio",
  //     title: "年龄分布",
  //     interval,
  //     trigger: false,
  //     api: `api/courier/ageRatio`,
  //     render: (res) => {
  //       const data = [
  //         {
  //           key: "19-24",
  //           label: "19-24",
  //           value: 0,
  //         },
  //         {
  //           key: "25-30",
  //           label: "25-30",
  //           value: 0,
  //         },
  //         {
  //           key: "31-45",
  //           label: "31-45",
  //           value: 0,
  //         },
  //         {
  //           key: "45-60",
  //           label: "45+",
  //           value: 0,
  //         },
  //       ];

  //       const total = (res.data || []).reduce((total, item) => {
  //         return total + item.value
  //       }, 0);

  //       (res.data || []).forEach(({range, value}) => {
  //         data.find((item) => {
  //           if (item.key === range.join('-')) {
  //             item.value = value;
  //             item.ratio = value / total * 100;

  //             return true;
  //           }

  //           return false;
  //         });
  //       });

  //       return data;
  //     }
  //   }]
  // },
  {
    id: "DistanceRank",
    key: "DistanceRank",
    title: "里程排行",
    interval,
    api: "api/statictis/distanceRanking?size=10",
    enlarge: {
      api: "api/statictis/distanceRanking?size=100",
    },
  },
];

export default {
  components: { ChartBox },
  data() {
    return {
      isFullScreen: !!document.fullscreenElement,
      updatetime: "12月18日 19:00:00",
      timers: {},
      remUnit: window.remUnit || 20,
      avgSpeed: 0,
      lgConfig: undefined,
      map: {
        amap: undefined,
        center: [117.0527, 36.68136],
        markers: [],
        winPosition: undefined,
        curVehicleId: undefined,
        carList: [],
        carIndex: -1,
        config: {
          id: "vehiclesRealTimeData",
          interval: 60 * 1000,
          _api: "api/statictis/vehiclesRealTimeData",
          render: (res) => {
            const { amap, markers } = this.map;
            const _markers = amap.getAllOverlays("marker");

            const newMarkers = (res || [])
              .map((item) => {
                const {
                  vehicleStatus,
                  chargeStatus,
                  location,
                  vehicleId,
                } = item;
                const [latStr, lngStr] = location.split(",");
                const idx =
                  chargeStatus === 1 ? 2 : vehicleStatus === 1 ? 1 : 0;

                if (!vehicleId) return undefined;

                // statusNums = ['', 'moving', 'charging'];
                const status = statusNums[idx];
                const lng = lngStr - 0;
                const lat = latStr - 0;
                const m = _markers.find((marker) => {
                  const { vehicleId: mVehicleId } = marker.getExtData();

                  return mVehicleId === vehicleId;
                });
                const ponit = markers.find(
                  (_m) => _m.vehicleId === vehicleId
                ) || {
                  position: [lng, lat],
                  offset: [-7, -14],
                  vehicleId,
                  status,
                };

                if (m) {
                  const extData = m.getExtData();
                  const pos = m.getPosition();
                  const trace = { lng, lat, status };

                  if (!extData.ponits) {
                    extData.ponits = [];
                  }

                  // console.log(extData.vehicleId, JSON.stringify(extData.ponits));

                  if (
                    extData.ponits.length === 0 &&
                    lng === pos.lng &&
                    lat === pos.lat
                  ) {
                    ponit.status = status;
                  } else {
                    const lastPonit = extData.ponits.pop();

                    // 相邻点去重
                    if (
                      !lastPonit ||
                      (lastPonit.lng === trace.lng &&
                        lastPonit.lat === trace.lat)
                    ) {
                      extData.ponits.push(trace);
                    } else {
                      extData.ponits.push(lastPonit, trace);
                    }

                    // console.log('render:', extData.vehicleId, status, JSON.stringify([pos.lng, pos.lat]), JSON.stringify(extData.ponits));

                    if (!extData.moving) {
                      const movePonit = extData.ponits[0];

                      // 车辆移动，强制设为移动状态
                      ponit.status = statusNums[1];

                      this.$nextTick(() => {
                        m.moveTo([movePonit.lng, movePonit.lat], {
                          duration: 300,
                          autoRotation: true,
                        });
                      });
                    }
                  }

                  m.setExtData(extData);
                }

                return ponit;
              })
              .filter((item) => !!item);
            console.log("newMarkers", newMarkers);
            this.map.markers = newMarkers;
            setTimeout(() => {
              this.map.amap.setFitView();
            },1000);
          },
        },
      },
      dataScreen: {
        vehicleTotalCount: 0,
        totalProtectTimes: 0,
        protectTimes: 0,
        totalDistance: 0,
        laps: 0,
        emissions: 0,
        trees: 0,
      },
      dataScreenShow: {
        vehicleTotalCount: 0,
        totalProtectTimes: 0,
        protectTimes: 0,
        totalDistance: 0,
        laps: 0,
        emissions: 0,
        trees: 0,
      },
      expressPointMap: undefined,
      expressPointCenter: [117.1649, 36.6767],
      expressPoints: [],
      chartConfig: {},
      charts: [
        {
          title: "总览数据",
          interval: 60 * 1000,
          api: "api/statictis/dataScreening",
          render: (res) => {
            const dataScreen = {};

            Object.entries(res).forEach(([key, value]) => {
              if (key === "totalDistance") {
                dataScreen.totalDistance = parseInt(value) || 0;
                dataScreen.laps = parseInt(value / 40) || 0;
                dataScreen.emissions = parseInt(value * 0.22) || 0;
                dataScreen.trees = parseInt((value * 0.22) / 78.5) || 0;
              } else {
                dataScreen[key] = parseInt(value) || 0;
              }
            });

            this.dataScreen = dataScreen;
          },
        },
        // {
        //   id: "avgSpeed",
        //   title: "平均时速",
        //   interval: 60 * 1000,
        //   api: 'api/statictis/avgSpeed',
        //   render: (res) => {
        //     this.avgSpeed = res;
        //   }
        // },
        {
          id: "expressPoint",
          title: "网店分布",
          interval,
          api: `api/statictis/expressPoint?location=117.1649,36.6767`,
          render: (res) => {
            this.expressPoints = (res || [])
              .map((item) => {
                const { location } = item;

                if (!location) return undefined;

                const [lat, lng] = location.split(",");
                const id = `${+new Date()}`;

                return {
                  id,
                  center: [lng, lat],
                };
              })
              .filter((item) => !!item);

            this.$nextTick(() => {
              this.checkOverlays();
            });
          },
        },
      ],
    };
  },
  watch: {
    dataScreen(val, oldVal) {
      const that = this;
      const { vehicleTotalCount } = val;
      const _dataScreen = { ...oldVal };

      TweenLite.to(_dataScreen, 1, {
        ...val,
        onUpdate() {
          const dataScreenShow = {};

          Object.entries(_dataScreen).forEach(([key, value]) => {
            if (key === "_gsap") return;

            dataScreenShow[key] = value;
          });

          that.dataScreenShow = dataScreenShow;
        },
      });

      if (!this.chartConfig.CarStatistic_0.vData) {
        this.chartConfig.CarStatistic_0.vData = {
          vehicleTotalCount,
          news: [],
        };
      } else {
        this.chartConfig.CarStatistic_0.vData = {
          ...this.chartConfig.CarStatistic_0.vData,
          vehicleTotalCount,
        };
      }
    },
  },
  mounted() {
    // this.initMap();
    // this.getData()
    mockDataFn.init();

    // 框架数据图表，不可配置
    this.charts.forEach((chartInfo) => {
      this.renderData(chartInfo);
    });

    // 可配置图表
    _chartConfig.forEach((cfg, index) => {
      const id = `${cfg.key}_${index}`;

      this.chartConfig[id] = cfg;

      this.fetchData(cfg, (vData) => {
        console.log(vData);
        this.chartConfig[id].vData = vData;
      });

      if (cfg.key === "ChartBoxGroup" && cfg.vData) {
        cfg.vData.forEach((_cfg, _index) => {
          this.fetchData(_cfg, (data) => {
         
            const { vData } = this.chartConfig[id];

            vData.splice(_index, 1, {
              ..._cfg,
              vData: data,
            });

            this.chartConfig[id].vData = [...vData];
          });
        });
      }

      if (cfg.key === "CarStatistic") {
        const { vehicleTotalCount } = this.dataScreen;

        cfg.vData = {
          vehicleTotalCount,
          news: [
            {
              id: 1,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "烟台邮政分公司",
            },
            {
              id: 2,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "东营邮政分公司",
            },
            {
              id: 3,
              txt: "中国邮政速递 **网点配置1台橙仕01",
              company: "日照邮政分公司",
            },
            {
              id: 4,
              txt: "中国邮政速递 **网点配置2台橙仕01",
              company: "济宁邮政分公司",
            },
            {
              id: 5,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "济宁邮政分公司",
            },
            {
              id: 6,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "东营邮政分公司",
            },
            {
              id: 7,
              txt: "中国邮政速递 **网点配置1台橙仕01",
              company: "烟台邮政分公司",
            },
            {
              id: 8,
              txt: "中国邮政速递 **网点配置2台橙仕01",
              company: "东营邮政分公司",
            },
            {
              id: 9,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "日照邮政分公司",
            },
            {
              id: 10,
              txt: "中国邮政速递 **网点配置4台橙仕01",
              company: "济宁邮政分公司",
            },
            {
              id: 11,
              txt: "中国邮政速递 **网点配置2台橙仕01",
              company: "烟台邮政分公司",
            },
            {
              id: 12,
              txt: "中国邮政速递 **网点配置2台橙仕01",
              company: "东营邮政分公司",
            },
            {
              id: 13,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "东营邮政分公司",
            },
            {
              id: 14,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "日照邮政分公司",
            },
            {
              id: 15,
              txt: "中国邮政速递 **网点配置1台橙仕01",
              company: "济宁邮政分公司",
            },
            {
              id: 16,
              txt: "中国邮政速递 **网点配置2台橙仕01",
              company: "烟台邮政分公司",
            },
            {
              id: 17,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "中通速递",
            },
            {
              id: 18,
              txt: "中国邮政速递 **网点配置3台橙仕01",
              company: "济宁邮政分公司",
            },
            {
              id: 19,
              txt: "中国邮政速递 **网点配置1台橙仕01",
              company: "烟台邮政分公司",
            },
          ],
        };
      }
    });

    window.onresize = () => {
      this.isFullScreen = !!document.fullscreenElement;
    };
  },
  beforeDestroy() {
    const { timers } = this;

    Object.keys(timers).forEach((key) => {
      if (timers[key]) clearTimeout(timers[key]);
    });
  },
  // mounted() {
  //   this.getData()
  // },
  methods: {
    fullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    },
    navigator(car) {
      window.location.href = `./detail?vehicleId=${car.vehicleId}`;
    },
    fetchData(cfg, cb) {
      const { timers } = this;
      const interval = () => {
        if (cfg.interval) {
          if (timers[cfg.id]) clearTimeout(timers[cfg.id]);

          timers[cfg.id] = setTimeout(queryData, cfg.interval);
        }
      };
      const queryData = () => {
        const isMult = Array.isArray(cfg.api);
        let reqs = [];

        if (isMult) {
          reqs = cfg.api.map((url) => {
            const [api, query] = url.split("?");

            return {
              key: api.replace(/(\S+)\/(\w+)/, "$2"),
              search: query ? `?${query}` : undefined,
            };
          });
          // reqs = cfg.api.map((api) => {
          //   return this.axios({
          //     url: `/${api}`,
          //     method: "get",
          //   });
          // })
        } else {
          const [api, query] = cfg.api.split("?");

          reqs[0] = {
            key: api.replace(/(\S+)\/(\w+)/, "$2"),
            search: query ? `?${query}` : undefined,
          };
          // reqs[0] = this.axios({
          //   url: `/${cfg.api}`,
          //   method: "get",
          // })
        }

        // this.axios.all(reqs).then(this.axios.spread((...rets) => {

        const rets = reqs.map(({ key, search }) => {
          console.log(key);
          return mockDataFn[key](search);
        });

        this.updatetime = dayjs().format("MM月DD日 HH:mm:ss");

        try {
          if (isMult) {
            cb && cb(cfg.render ? cfg.render(rets) : rets);
          } else {
            cb && cb(cfg.render ? cfg.render(rets[0]) : rets[0]);
          }
        } catch (error) {
          console.log("render error:", error);
        }

        interval();
        // })).catch(() => {
        //   // 请求失败重试
        //   interval();
        // });
      };

      if (!cfg.api) return;

      queryData();
    },
    renderData(chartInfo) {
      const { timers } = this;
      const interval = () => {
        if (chartInfo.interval) {
          if (timers[chartInfo.id]) clearTimeout(timers[chartInfo.id]);

          timers[chartInfo.id] = setTimeout(queryData, chartInfo.interval);
        }
      };
      const queryData = () => {
        // this.axios({
        //   url: `/${chartInfo.api}`,
        //   method: "get",
        // }).then((res) => {
        const [api, query] = chartInfo.api.split("?");
        const key = api.replace(/(\S+)\/(\w+)/, "$2");
        const search = query ? `?${query}` : undefined;
        const res = mockDataFn[key](search);
        this.updatetime = dayjs().format("MM月DD日 HH:mm:ss");

        try {
          if (chartInfo.render) {
            chartInfo.render(res);
          }
        } catch (error) {
          console.log("render error:", error);
        }

        interval();
        // }).catch(() => {
        //   // 请求失败重试
        //   interval();
        // });
      };

      if (!chartInfo.api) return;

      queryData();
    },
    enlarge(ev, config) {
      const { enlarge = {}, ...others } = config;
      let lgConfig = {};

      if (Array.isArray(enlarge.tabs) && enlarge.tabs.length > 0) {
        const { defaultCurrent, api, ...lgOthers } = enlarge;

        lgConfig = {
          ...others,
          ...lgOthers,
          current: defaultCurrent,
          api: api[defaultCurrent],
          apiList: api,
        };
      } else {
        lgConfig = {
          ...others,
          ...enlarge,
        };
      }

      this.fetchData(lgConfig, (vData) => {
        lgConfig.vData = vData;
        this.lgConfig = lgConfig;
      });
    },
    dislarge() {
      this.lgConfig = undefined;
    },
    changeTab(config, index) {
      const { apiList } = config;
      const lgConfig = {
        ...config,
      };

      lgConfig.api = apiList[index];
      lgConfig.current = index;

      this.fetchData(lgConfig, (vData) => {
        lgConfig.vData = vData;
        this.lgConfig = lgConfig;
      });
    },
    mapClick() {
      this.map.carIndex = -1;
      this.map.curVehicleId = undefined;
    },
    mapMoveend() {
      this.mapDataRender();
    },
    mapDataRender() {
      const { amap, config } = this.map;
      const { southWest, northEast } = amap.getBounds();

      const topLeft = `${northEast.lat},${southWest.lng}`;
      const bottomRight = `${southWest.lat},${northEast.lng}`;
      const newApi = `${config._api}?topLeft=${topLeft}&bottomRight=${bottomRight}`;

      if (config.api !== newApi) {
        config.api = newApi;

        this.renderData(config);
        this.map.amap.setFitView();
      }
    },
    mapComplete(amap) {
      this.map.amap = amap;

      this.$nextTick(() => {
        this.mapDataRender();
      });
    },
    // status = ['actived', 'moving', 'charging'];
    getMarkerContent(status = "", vehicleId) {
      const { $style } = this;
      const carType = vehicleIds[vehicleId] || "default";

      // vehicleIds, markerColors

      const { car = "#ffffff", logo } = markerColors[carType];
      let cls = `${$style.carMarker} ${status} `;

      if (carType !== "default") {
        cls += $style["carMarker" + carType];
      }

      return `<div class="${cls}">
        <svg class="icon" width="14" height="28" viewBox="0 0 14 28" xmlns="http://www.w3.org/2000/svg">
          <g transform="rotate(-90 14 14)" fill="none" fill-rule="evenodd">
            <path d="M21.438 0c.503 0 .94.283 1.16.7H25a3 3 0 0 1 3 3v6.6a3 3 0 0 1-3 3h-2.401a1.312 1.312 0 0 1-2.323 0H3a3 3 0 0 1-3-3V3.7a3 3 0 0 1 3-3h17.277c.22-.417.657-.7 1.16-.7z" fill="${car}"/>
            <rect stroke="#979797" stroke-width=".25" fill="#CBCBCB" opacity=".253" x="1.125" y="2.125" width="22.75" height="9.75" rx="3"/>
            <path d="M21.088 2h3.641a1 1 0 0 1 .994.89C25.908 4.55 26 6.04 26 7.359c0 1.26-.084 2.517-.253 3.774a1 1 0 0 1-.99.867h-3.663a1 1 0 0 1-.997-1.077c.095-1.23.143-2.503.143-3.817 0-1.344-.05-2.688-.15-4.032A1 1 0 0 1 21.088 2z" fill="#10130C"/>
            ${logo}
          </g>
        </svg>
        <i></i>
      </div>`;
    },
    carMarkerPrev() {
      const { carIndex } = this.map;

      if (carIndex === 0) return;

      this.map.carIndex = carIndex - 1;
    },
    carMarkerNext() {
      const { carIndex, carList } = this.map;

      if (carIndex === carList.length - 1) return;

      this.map.carIndex = carIndex + 1;
    },
    markerClick(ev) {
      const { amap } = this.map;
      const zoom = amap.getZoom();
      const markers = amap.getAllOverlays("marker");
      const { target } = ev;
      const { lat, lng } = target.getPosition();

      markers.some((marker) => {
        const { vehicleId } = marker.getExtData();

        if (target._amap_id === marker._amap_id) {
          this.map.curVehicleId = vehicleId;
          this.map.carIndex = -1;

          return true;
        }

        return false;
      });

      this.axios({
        url: `/api/statictis/currentVehicleInfo?location=${lat},${lng}&zooms=${zoom}`,
        method: "get",
      }).then((res) => {
        if (!res || !res.length) return;

        this.map.winPosition = [lng, lat];
        this.map.carList = res;
        this.map.carIndex = 0;
        // this.map.curVehicleId = res.data[0].vehicleId;
      });
    },
    markerMoving(ev) {
      const { target } = ev;
      const extData = target.getExtData();

      extData.moving = true;

      target.setExtData(extData);
    },
    markerMoveend(ev) {
      const { markers } = this.map;
      const { target } = ev;
      const extData = target.getExtData();
      const pos = target.getPosition();
      const endTrace = extData.ponits.shift();
      const { status } = endTrace;
      const getTrace = () => {
        const trace = extData.ponits[0];

        if (trace) {
          if (trace.lng === pos.lng && trace.lat === pos.lat) {
            extData.ponits.shift();

            return getTrace();
          }
        }

        return trace;
      };
      const trace = getTrace();
      const curMarker = markers.find((m) => m.vehicleId === extData.vehicleId);

      // console.log('markerMoveend: ', extData.vehicleId, status, JSON.stringify(extData.ponits), JSON.stringify(trace));

      if (!trace) {
        curMarker.status = status;
        extData.moving = false;
      } else {
        // console.log('markerMoveend moveTo:', extData.vehicleId, JSON.stringify([pos.lng, pos.lat]), JSON.stringify([trace.lng, trace.lat]));
        curMarker.status = statusNums[1];

        this.$nextTick(() => {
          target.moveTo([trace.lng, trace.lat], {
            duration: 300,
            autoRotation: true,
          });
        });
      }

      target.setExtData(extData);
    },
    getData() {
      getVehicleBatch().then((res) => {
        console.log(res);
      });
    },
    aMapComplete(amap) {
      this.expressPointMap = amap;
      // const makers = this.expressPointMap.getAllOverlays('marker');
      // this.expressPointMap.setFitView(makers)
    },
    checkOverlays() {
      const { expressPoints, expressPointMap } = this;
      let reChecked = false;

      if (!expressPointMap) {
        reChecked = true;
      } else {
        const makers = expressPointMap.getAllOverlays("marker");

        if (
          !makers.length ||
          expressPoints.length !== makers.length ||
          expressPoints[0].id !== makers[0].getExtData().id
        ) {
          reChecked = true;
        }
      }

      if (reChecked) {
        setTimeout(() => this.checkOverlays(), 500);
      } else {
        const makers = expressPointMap.getAllOverlays("marker");

        expressPointMap.setFitView(makers, false, [5, 5, 5, 5], 14);
      }
    },
  },
};
</script>
