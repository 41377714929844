import Vue from 'vue'

const components = [
  'VehicleDistribution',
  'CarStatistic',
  'CarStatus',
  'MileageStatistic',
  'MileageTrend',
  'DistanceSegment',
  'TimeQuantum',
  'SexRatio',
  'AgeRatio',
  'DistanceRank',
]

components.forEach(key => {
  Vue.component(key, () => import(`@/components/${key}`))
})