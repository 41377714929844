<template>
  <div class="box">
    <header class="hd" v-if="title !== 'noHd'">
      <h3 v-if="title" class="title">{{title}}</h3>
      <slot name="extra"></slot>
      <!-- <span v-if="trigger" class="trigger" @click="enlarge">
        <svg class="svg" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M5.985 4.503a1.5 1.5 0 0 1 1.082.428c.278.276.433.643.433 1.072 0 .428-.155.796-.433 1.071-.31.276-.65.429-1.082.429a1.48 1.48 0 0 1-1.052-.429C4.623 6.8 4.5 6.431 4.5 5.972c0-.428.155-.765.433-1.071a1.536 1.536 0 0 1 1.052-.398zm0-4.003a1.5 1.5 0 0 1 1.082.429c.278.275.433.642.433 1.071 0 .429-.155.796-.433 1.071-.31.276-.65.429-1.082.429a1.48 1.48 0 0 1-1.052-.429C4.623 2.796 4.5 2.43 4.5 1.97c0-.428.155-.765.433-1.071A1.536 1.536 0 0 1 5.985.5zm0 8a1.5 1.5 0 0 1 1.082.429c.278.275.433.642.433 1.071 0 .429-.155.796-.433 1.071-.31.276-.65.429-1.082.429a1.48 1.48 0 0 1-1.052-.429c-.31-.275-.433-.642-.433-1.102 0-.428.155-.765.433-1.071.278-.276.619-.398 1.052-.398z" fill="#5F659B" fill-rule="evenodd" opacity=".598"/></svg>
      </span> -->
    </header>
    <div class="bd">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.boxGroup {
  display: flex;

  .box {
    flex: 1;
    overflow: hidden;

    & + .box {
      margin-left: .4rem;
    }
  }
}
.box {
  margin-bottom: .4rem;
  border-radius: .5rem;
  background-color: #2C315D;

  .hd {
    display: flex;
    padding: .7rem 1rem .5rem;
    align-items: center;

    .title {
      flex: 1;
      font-size: .8rem;
      line-height: 1.3rem;
    }

    .trigger {
      position: relative;
      padding: 0.35rem .75rem;
      margin-right: -1rem;
      cursor: pointer;
      z-index: 99;

      .svg {
        display: block;
        width: .6rem;
        height: .6rem;
      }
    }
  }

  .tab {
    display: flex;
    margin-left: 1.2rem;
    padding: 0 .4rem;
    background-color: #1F2344;
    border-radius: 1.3rem;

    li {
      padding: 0 .4rem;
      font-size: .7rem;
      line-height: 1.3rem;
      color: #888FCD;
      cursor: pointer;
      transition: color .3s;

      &.current {
        color: #fff;
      }
    }
  }

  .bd {
    display: flex;
    padding: 0 1rem .5rem;
    flex-wrap: wrap;
  }

  .chart {
    flex: 1;
    position: relative;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    trigger: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    enlarge(ev) {
      this.$emit('enlarge', ev);
    }
  },
};
</script>