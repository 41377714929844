<template>
  <div :class="$style.page">
    <div :class="$style.container">
      <div :class="{
        [$style.side]: true,
        [$style.expend]: sideExpend,
      }">
        <div :class="`${$style.box} ${$style.infoBox}`">
          <div :class="$style.summary">
            <img
              :class="$style.pic"
              width="56"
              height="56"
              :src="userInfo.cover || 'https://juzhenimage.oss-cn-shanghai.aliyuncs.com/img/ac7.png'"
            />
            <div :class="$style.infos">
              <p :class="$style.name">{{ userInfo.vehicleModel || "车型" }}</p>
              <div :class="$style.metas">{{ userInfo.numPlate }}</div>
            </div>
          </div>
          <ul :class="$style.attrs">
            <li>
              <span :class="$style.t">服务时长</span>
              <span :class="$style.v">{{ userInfo.serviceTime }}天</span>
            </li>
            <li>
              <span :class="$style.t">今日运行</span>
              <span :class="$style.v">{{ parseInt(dayData.driveDuration, 10) || 0 }}小时</span>
            </li>
            <li>
              <span :class="$style.t">行驶状态</span>
              <span :class="$style.v">
                <span :class="carStatus">{{carStatusTxt}}</span>
              </span>
            </li>
          </ul>
        </div>

        <div :class="`${$style.box} ${$style.infoBox}`">
          <div :class="$style.summary">
            <img
              :class="$style.pic"
              width="56"
              height="56"
              :src="userInfo.picture"
            />
            <div :class="$style.infos">
              <h4 :class="$style.name">{{ userInfo.realName || "姓名" }}</h4>
              <div :class="$style.metas">{{ userInfo.mobile || '手机号' }}</div>
            </div>
          </div>
          <ul :class="$style.attrs">
            <li>
              <span :class="$style.t">年龄</span>
              <span :class="$style.v">{{ userInfo.age }}岁</span>
            </li>
            <!-- <li>
              <span :class="$style.t">驾龄</span>
              <span :class="$style.v">{{ userInfo.drivingAge ? `${userInfo.drivingAge}年` : '-' }}</span>
            </li> -->
            <li>
              <span :class="$style.t">性别</span>
              <span :class="$style.v">{{ userInfo.sex ? '女' : '男' }}</span>
            </li>
          </ul>
        </div>

        <div :class="`${$style.box} ${$style.metaBox}`">
          <div :class="$style.summary">
            <div :class="$style.title">
              <h3 :class="$style.t">预计可行驶</h3>
              <p><strong>{{estimatedMileageShow | formatNumber}}</strong> km</p>
            </div>

            <div :class="$style.sub">
              <h4 :class="$style.t">剩余电量</h4>
              <div :class="$style.progressBar">
                <div :class="$style.progress" :style="`width: ${bettryInfo.curBattery || 0}%`"></div>
              </div>
              <p>{{curBatteryShow | formatNumber}}%</p>
            </div>
          </div>
          
          <ul :class="$style.attrs">
            <li>
              <svg :class="$style.svg" width="16" height="16" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#A1A5CD"><path d="M8.5.5v1h2v10h-8v-10h2v-1h4z"/><path stroke-linecap="square" d="M5.25 5.5h2.5M5.25 8.5h2.5"/></g></svg>
              <span :class="$style.t">今日耗电</span>
              <strong>{{ parseInt(bettryInfo.power) || 0 }}kwh</strong>
            </li>
            <li>
              <svg :class="$style.svg" width="16" height="16" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#A1A5CD"><path d="M8.5.5v1h2v10h-8v-10h2v-1h4z"/><path d="M6.422 3L5 5.647h2.656L5.504 8.85"/></g></svg>
              <span :class="$style.t">充满时间</span>
              <strong>{{ expectedChargingTime }}</strong>
            </li>
          </ul>
        </div>

        <div :class="`${$style.box} ${$style.metaBox}`">
          <div :class="$style.summary">
            <div :class="$style.title">
              <h3 :class="$style.t">今日里程</h3>
              <p><strong>{{dayData.driveMiles | formatNumber}}</strong> km</p>
            </div>
            <div :class="$style.sub">
              <h4 :class="$style.t">累计里程</h4>
              <p>{{dayData.totalMiles | formatNumber}}km</p>
            </div>
          </div>

          <ul :class="$style.attrs">
            <li>
              <span :class="$style.t">本月配送次数</span>
              <strong>{{ dayData.monthDeliverTimes || 0 }}次</strong>
            </li>
            <li>
              <span :class="$style.t">今日配送驿站</span>
              <strong>{{ dayData.poiLen || 0 }}个</strong>
            </li>
          </ul>
        </div>
      </div>

      <div :class="$style.main">
        <div id="vehicleTrace" :class="$style.mapBox">
          <p :class="$style.extra">
            <span :class="$style.updateTime">更新时间：{{updatetime}}</span>
            <span :class="$style.fullScreen" @click="fullScreen">
              <svg v-if="isFullScreen" :class="$style.svg" width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M5.292 8.255c.017 0 .034.002.051.004l.013.003.024.003a.57.57 0 0 1 .064.017l.006.004a.55.55 0 0 1 .232.145c.106.11.164.259.161.413v3.493c0 .322-.26.584-.582.584H5.26a.583.583 0 0 1-.582-.584v-2.118l-3.69 3.603a.57.57 0 0 1-.971-.267.585.585 0 0 1 .171-.565L3.842 9.42H1.76a.583.583 0 0 1 0-1.167h3.533zm2.89.355a.72.72 0 0 1 .033-.1l.01-.02a.58.58 0 0 1 .443-.318l.026-.002a.416.416 0 0 1 .054-.003h3.478c.32 0 .58.257.58.574v.001c0 .317-.26.574-.58.574l-2.079.001 3.673 3.698c.155.145.216.36.16.562a.582.582 0 0 1-.431.407.6.6 0 0 1-.575-.171l-3.64-3.665v2.104a.583.583 0 0 1-1.167-.001V8.708l.004-.034v-.01.007l.01-.061zM1.01.187l3.57 3.655V1.76a.582.582 0 0 1 .582-.582.584.584 0 0 1 .584.582v3.523l-.002.034v-.035l-.004.058-.005.032a.346.346 0 0 1-.01.052l-.009.022a.558.558 0 0 1-.147.236l-.03.027a.578.578 0 0 1-.198.109l-.031.009a.474.474 0 0 1-.11.016l-.016.001H1.708a.579.579 0 0 1-.578-.58v-.001c0-.32.259-.58.578-.58h2.079L.177.987A.57.57 0 0 1 .446.016a.585.585 0 0 1 .565.171zM13.574.02a.579.579 0 0 1 .237.989l-3.777 3.658h2.167a.583.583 0 0 1 0 1.166H8.69a.577.577 0 0 1-.575-.58V1.773c0-.154.06-.302.168-.41a.57.57 0 0 1 .406-.17h.002a.57.57 0 0 1 .406.17.584.584 0 0 1 .168.41V3.8l3.74-3.623a.576.576 0 0 1 .568-.157z" fill="#FEFEFF" fill-rule="evenodd"/></svg>
              <svg v-else :class="$style.svg" width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M5.42 8.275a.585.585 0 0 1 .236.99l-3.655 3.568h2.083a.583.583 0 0 1 0 1.167H.552a.573.573 0 0 1-.052-.005l-.012-.003-.025-.003a.57.57 0 0 1-.063-.016l-.007-.004a.55.55 0 0 1-.231-.146.582.582 0 0 1-.162-.412V9.918c0-.323.26-.585.582-.585h.002c.322 0 .583.262.583.585l-.001 2.117 3.69-3.603a.57.57 0 0 1 .564-.157zm3.772.079l3.64 3.665.001-2.104a.583.583 0 0 1 1.167 0v3.543l-.003.022v.013a.566.566 0 0 1-.045.163l-.011.021a.58.58 0 0 1-.442.318l-.027.002-.026.002L9.941 14a.577.577 0 0 1-.581-.574v-.002c0-.317.26-.574.58-.574h2.08L8.347 9.152a.562.562 0 0 1-.16-.563.582.582 0 0 1 .431-.406.6.6 0 0 1 .574.17zM4.038 0c.319 0 .578.26.578.58v.002c0 .32-.259.58-.578.58L1.959 1.16l3.61 3.695a.57.57 0 0 1-.268.971.585.585 0 0 1-.566-.171L1.166 2.001v2.084a.582.582 0 0 1-.583.582A.584.584 0 0 1 0 4.085V.562C0 .539.003.516.006.493L.004.514.003.526 0 .561.004.514l.01-.068A.432.432 0 0 1 .022.42L.03.398A.558.558 0 0 1 .177.162l.03-.026a.578.578 0 0 1 .198-.11L.436.018A.474.474 0 0 1 .49.007L.546 0 4.038 0zm9.388 0c.317 0 .574.26.574.58v3.48c0 .153-.06.3-.168.41a.57.57 0 0 1-.406.17h-.002a.57.57 0 0 1-.405-.17.584.584 0 0 1-.169-.41V2.032L9.11 5.656a.576.576 0 0 1-.977-.267.581.581 0 0 1 .172-.564l3.777-3.659H9.916a.583.583 0 0 1 0-1.166h3.51z" fill="#FEFEFF" fill-rule="evenodd"/></svg>
            </span>
          </p>
          <div v-if="isHistory" :class="$style.polylineLoading">
            <svg :class="$style.svg" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="50%" y1="88.888%" x2="78.689%" y2="-3.043%" id="app_a">
                  <stop stop-color="#1E3A56" offset="0%"/>
                  <stop stop-color="#1ECECE" offset="100%"/>
                </linearGradient>
              </defs>
              <g stroke-width="4" fill="none" fill-rule="evenodd">
                <circle stroke="#1ECECE" opacity=".135" cx="10" cy="10" r="8"/>
                <path :class="$style.circle" d="M18 10a8 8 0 1 0-8 8" stroke="url(#app_a)" stroke-linecap="round"/>
              </g>
            </svg>
            正在绘制车辆历史行驶轨迹
          </div>
          <amap
            map-style="amap://styles/161416970c7c92f52d88092c7b514b01"
            :zoom="15"
            :zoom-enable="false"
            :rotate-enable="false"
            :touch-zoom="false"
            :center.sync="map.center"
            @complete="mapComplete"
            @moveend="mapMoveend"
            @click="poiMaskerClick"
          >
            <amap-marker v-for="(poi, index) in pois"
              :key="poi.poiKey"
              :position="poi.pos"
              :content="getPoiContent(poi)"
              :offset="poi.offset"
              :zIndex="(curPoi && poi.poiKey === curPoi.poiKey) ? pois.length + 1 : index"
              @click="poiClick(poi)"
            />

            <amap-polyline
              :path="car.path"
              :stroke-weight="3"
              :stroke-opacity="0.4"
              stroke-color="#00F6E5"
            />
            <amap-marker
              :position.sync="car.pos"
              :angle.sync="car.angle"
              :content="car.content"
              :offset="car.offset"
              :visible="car.visible"
              :zIndex="999"
              @amap-ready="onCarReady"
              @moving="onCarMoving"
              @moveend="onCarMoveend"
            />
          </amap>

          <transition name="poiModal">
            <div :class="$style.timeline" v-if="!!curPoi && !curPoi.disabled" :key="curPoi.poiKey">
              <div :class="$style.wrap">
                <h4>{{curPoi.name}}</h4>
                <ol>
                  <li v-for="(times, index) in curPoi.timeline" :key="index">
                    <span :class="$style.in">{{times[0]}}</span>
                    <span :class="$style.out">{{times[1]}}</span>
                  </li>
                </ol>
              </div>
            </div>
          </transition>
        </div>
        
        <i :class="$style.trigger" @click="sideExpend = !sideExpend"> {{ sideExpend ? '&lt;' : '&gt;' }}</i>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.svg {
  display: inline-block;
  vertical-align: top;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem .8rem;
  font-size: .7rem;
  line-height: 1rem;
  color: #A5A9D1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: auto;
}

.container {
  display: flex;
  flex: 1;

  .side {
    display: flex;
    flex-direction: column;
    width: 0;
    overflow: hidden;
    transition: width .3s;

    &.expend {
      margin-right: .5rem;
      width: 17rem;
      min-width: 250px;
    }

    .box {
      width: 17rem;
      min-width: 250px;
    }

    .box + .box {
      margin-top: .5rem;
    }
  }

  .main {
    position: relative;
    display: flex;
    height: calc(100vh - 2rem);
    flex: 1;
    flex-direction: column;

    .trigger {
      position: absolute;
      top: 50%;
      left: .4rem;
      margin-top: -2.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 4.4rem;
      color: #fff;
      font-style: normal;
      background: #23284F;
      border-radius: .2rem;
      border: 1px solid #343B74;
      cursor: pointer;
    }
  }
}

.group {
  display: flex;

  .box {
    flex: 1 0 0;
    overflow: hidden;

    & + .box {
      margin-left: .4rem;
    }
  }

  & + .box {
    margin-top: .6rem;
  }
}

.box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.8rem 1.8rem;
  border-radius: .4rem;
  background-color: #2C315D;

  & + .group {
    margin-top: .6rem;
  }
}

.metaBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .summary {
    width: 6.4rem;

    h3, h4 {
      font-size: .7rem;
      font-weight: normal;
    }

    p {
      color: #fff;
    }

    .title {
      margin-bottom: .6rem;

      strong {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }
  }


  .progressBar {
    margin: .5rem 0 .3rem;
    height: .5rem;
    flex: 1;
    overflow: hidden;
    border-radius: .5rem;
    background-color: #343B74;

    .progress {
      height: .5rem;
      width: 0;
      transition: width 1s;
      background-image: linear-gradient(153deg, #FE9B86 0%, #F15887 100%);
    }
  }
  .attrs {
    width: 4.8rem;

    li {
      margin-top: .8rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      line-height: 1rem;

      .svg {
        margin-right: .4rem;
        width: .8rem;
        height: .8rem;
      }

      strong {
        display: block;
        padding-top: .1rem;
        width: 100%;
        color: #fff;
        font-weight: normal;
      }
    }
  }
}

.timeline {
  position: absolute;
  top: 5rem;
  right: 1.5rem;
  display: flex;
  max-height: calc(100% - 6rem);
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;

  .wrap {
    padding: 12px 0 4px 20px;
    display: flex;
    flex-direction: column;
    width: 186px;
    overflow: hidden;
    background: rgba($color: #1F2345, $alpha: .85);
    border: 1px solid #00FBE5;
    border-radius: 7px;
    transform-origin: 50% 0%;
    transition: all 0.12s ease;
  }

  h4 {
    margin-bottom: 2px;
    font-size: 14px;
    color: #888FCD;
    line-height: 24px;
  }

  ol {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 140%;
    overflow-y: scroll;
    overflow-x: hidden;

    li {
      margin: 12px 0 12px 10px;
      padding-left: 18px;
      border-left: 2px dashed #343B74;

      span {
        display: block;
        position: relative;

        &::before {
          position: absolute;
          top: -11px;
          left: -41px;
          width: 44px;
          height: 44px;
          text-align: center;
          font-size: 20px;
          content: '到';
          line-height: 44px;
          transform: scale(.5);
          border-radius: 50%;
          background-color: #5CBC54;
        }

        &.out {
          margin-top: 18px;

          &::before {
            content: '离';
            background-color: #FF3A5D;
          }
        }
      }
    }
  }
}
:global(.poiModal-enter-active), :global(.poiModal-leave-active) {
  transition: opacity .12s;
}
:global(.poiModal-enter), :global(.poiModal-leave-to) {
  opacity: 0;
}

:global(.poiModal-enter) .wrap {
  transform: scaleY(0);
}

.infoBox {
  // padding: 2rem 1rem 1.6rem 2rem;

  .summary {
    display: flex;
    position: relative;
    align-items: center;

    .pic {
      margin-right: .5rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }

    .infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        color: #fff;
        font-size: .8rem;
        line-height: 1.1rem;
        font-weight: bold;
      }

      .metas {
        display: flex;
        line-height: 1rem;
      }
    }
  }

  .attrs {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    li {
      display: flex;
      // padding: 0 .2rem;
      flex: auto;
      flex-direction: column;
      line-height: 1rem;

      & + li {
        margin-left: .5rem;
      }

      .v {
        color: #FFFFFF;
        font-weight: bold;
      }

      .moving, .charging {
        &::before {
          margin: .25rem .3rem 0 0;
          display: inline-block;
          vertical-align: top;
          width: .5rem;
          height: .5rem;
          content: '';
          border-radius: .5rem;
          background-color: #00BF83;
        }
      }
    }
  }
}

.mapBox {
  position: relative;
  flex: 1;
  border-radius: .4rem;
  overflow: hidden;
  background-color: #2C315D;

  .extra {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    z-index: 5;

    .updateTime, .fullScreen {
      background: rgba($color: #1F2345, $alpha: .8);
      border: 1px solid rgba($color: #9398C0, $alpha: .8);
      border-radius: 1.6rem;
    }

    .updateTime {
      margin: 0 .8rem 0 .5rem;
      padding: 0 .8rem;
      font-size: .8rem;
      line-height: 1.6rem;
      color: #A5A9D1;
    }

    .fullScreen {
      padding: 0.45rem 1rem;

      .svg {
        display: block;
        width: 0.7rem;
        height: 0.7rem;
      }
    }
  }
}

.polylineLoading {
  position: absolute;
  top: 1.8rem;
  left: 1.5rem;
  display: flex;
  padding: .5rem 1rem .5rem .6rem;
  font-size: .8rem;
  line-height: 1rem;
  color: #FFFFFF;
  background: rgba($color: #1F2345, $alpha: .8);
  border: 1px solid rgba($color: #9398C0, $alpha: .8);
  border-radius: 2rem;
  z-index: 9;

  .svg {
    margin-right: .6rem;
    width: 1rem;
    height: 1rem;
  }
}

.poi {
  p {
    position: absolute;
    top: 0;
    left: 26px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    white-space: nowrap;
    background-color: #2C315D;
    border: 1px solid #343B74;
    border-radius: 24px;
  }

  &.disabled {
    opacity: .5;
  }

  &.actived {
    p {
      border-color: #00FBE5;
    }
  }
}

.circle {
  transform-origin: 50%;
  animation: circle 1.5s linear infinite;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
import { loadAmap } from '@amap/amap-vue';
import dayjs from "dayjs";
import { TweenLite } from 'gsap';
import avatar from '../assets/img/avatar.jpg';
import { config } from '../config';

// 全局刷新时间，默认10秒
const interval = 10000;
const today = dayjs();
const todayTs = today.valueOf();
const endTime = today.format("YYYYMMDD");
const startTime = today.subtract(6, "days").format("YYYYMMDD");
const { poiData, vehicleIds } = config;

export default {
  data() {
    const { vehicleId, moveStatus = 'start' } = this.$route.query;

    return {
      vehicleId,
      curPoi: undefined,
      timestamp: undefined,
      updatetime: '12月18日 19:00:00',
      isFullScreen: !!document.fullscreenElement,
      moveStatus,
      sideExpend: true,
      timers: {},
      // 地图相关对象
      map: {
        center: [ 117, 36 ],
        config: {
          id: "vehicleTrace",
          interval: 5000,
          _api: `api/courier/vehicleTrace?vehicleId=${vehicleId}`,
          render: (res) => {
            this.addVehicleTrace(res.data || [], !this.timestamp);
          },
        },
      },
      autoFit: true,
      autoFitTimer: undefined,
      pois: [],
      car: {
        marker: undefined,
        pos: [ 117, 36 ],
        visible: false,
        content: '<svg class="icon" style="display: block;" width="14" height="28" viewBox="0 0 14 28" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="97.04%" y1="50%" x2="0%" y2="50%" id="a"><stop stop-color="#FFF" offset="0%"/><stop stop-color="#ECE9E9" offset="100%"/></linearGradient></defs><g transform="rotate(-90 14 14)" fill="none" fill-rule="evenodd"><path d="M21.438 0c.503 0 .94.283 1.16.7H25a3 3 0 0 1 3 3v6.6a3 3 0 0 1-3 3h-2.401a1.312 1.312 0 0 1-2.323 0H3a3 3 0 0 1-3-3V3.7a3 3 0 0 1 3-3h17.277c.22-.417.657-.7 1.16-.7z" fill="url(#a)"/><rect stroke="#979797" stroke-width=".25" fill="#CBCBCB" opacity=".253" x="1.125" y="2.125" width="22.75" height="9.75" rx="3"/><path d="M21.088 2h3.641a1 1 0 0 1 .994.89C25.908 4.55 26 6.04 26 7.359c0 1.26-.084 2.517-.253 3.774a1 1 0 0 1-.99.867h-3.663a1 1 0 0 1-.997-1.077c.095-1.23.143-2.503.143-3.817 0-1.344-.05-2.688-.15-4.032A1 1 0 0 1 21.088 2z" fill="#10130C"/></g></svg>',
        // icon: {
        //   image: "https://juzhenimage.oss-cn-shanghai.aliyuncs.com/uploads/2021/03/17/icon_car.png",
        //   size: [20, 38],
        //   imageSize: [20, 38]
        // },
        offset: [-7, -14],
        angle: 0,
        autoRotation: true,
        path: undefined,
        pathPoi: undefined,
      },
      carStatus: '',
      carStatusTxt: '未运行',
      // 小车状态
      moving: false,
      // 历史提示
      isHistory: false,
      // 增量轨迹点
      vehicleTrace: [],
      distributionInfo: {},
      userInfo: {
        numPlate: '车牌号'
      },
      bettryInfo: {},
      estimatedMileageShow: 0,
      curBatteryShow: 0,
      dayData: {},
      charts: [
        {
          id: "userInfo",
          title: "小哥信息",
          interval: 60 * 1000,
          api: `api/courier/userInfo?vehicleId=${vehicleId}`,
          render: (res) => {
            const { picture, birthday, manuDate, ...userInfo } = res.data;

            const serviceTime = manuDate ? dayjs().diff(manuDate, 'day') : 0;
            const age = birthday ? dayjs().diff(birthday, 'year') : 0;
            const drivingAge = 0;

            this.userInfo = {
              ...userInfo,
              serviceTime,
              age,
              drivingAge,
              picture: picture || avatar,
            };
          },
        },
        {
          id: "batteryInfo",
          title: "电池信息",
          interval,
          api: `api/courier/bettryInfo?vehicleId=${vehicleId}`,
          render: (res) => {
            this.bettryInfo = res.data;
          },
        },
        {
          id: "dailyData",
          interval,
          api: `api/courier/dailyData?vehicleId=${vehicleId}`,
          render: (res) => {
            const { dayData } = this;
            const { driveDuration } = res.data;

            this.dayData = {
              ...dayData,
              driveDuration,
            }
          },
        },
        {
          id: "mileageData",
          title: "里程数据",
          interval: 60 * 1000,
          api: `api/courier/mileageData?vehicleId=${vehicleId}&startTime=${startTime}&endTime=${endTime}`,
          render: (res) => {
            const { dayData } = this;
            const last = res.data.pop();
            const { driveMiles, totalMiles } = last;

            this.dayData = {
              ...dayData,
              driveMiles,
              totalMiles,
              monthDeliverTimes: dayjs().date()
            }
          }
        },
        {
          id: "pois",
          interval,
          api: `api/courier/poi?vehicleId=${vehicleId}`,
          render: (res) => {
            const isSpe = vehicleIds[vehicleId];
            const data = [];
            const _data = [];

            if (res.data && res.data.length > 0) {
              res.data.forEach((item) => {
                const { poiKey, location, name, poiType, startTs, endTs } = item;
                const exist = data.find((_item) => _item.poiKey === poiKey);
                const time = [
                  dayjs(startTs).format("HH:mm"),
                  dayjs(endTs).format("HH:mm"),
                ];

                if (exist) {
                  exist.timeline.push(time);
                } else {
                  _data.push(item);
                  data.push({
                    poiKey,
                    location,
                    name,
                    poiType,
                    timeline: [ time ]
                  });
                }
              });
            }

            if (isSpe) {
              poiData.forEach((poi) => {
                if (!_data.some((_poi) => _poi.poiKey === poi.key)) {
                  const { key, name, poiType = 2, location } = poi;

                  data.push({ poiKey: key, name, poiType, location, disabled: true });
                }
              })
            }

            this.dayData.poiLen = _data.filter((item) => item.poiType === 2).length;
            this.pois = data.map((poi) => {
              const { poiKey, name, poiType, location, timeline, disabled } = poi;
              
              return {
                pos: location.split(',').reverse(),
                offset: [-7, -14],
                disabled,
                name,
                poiKey,
                poiType,
                timeline,
              }
            });
          }
        },
      ],
    };
  },
  watch: {
    bettryInfo(val) {
      const { estimatedMileage, curBattery } = val;

      TweenLite.to(this.$data, 1, {
        estimatedMileageShow: estimatedMileage,
        curBatteryShow: curBattery,
      });
    },
    userInfo(val) {
      const { $style } = this;
      const statusNums = ['', $style.moving, $style.charging];
      const statusTxtNums = ['未运行', '运行中', '充电中'];
      const { chargeStatus, vehicleStatus } = val;
      const idx = chargeStatus === 1 ? 2 :
                vehicleStatus === 1 ? 1 : 0;

      this.carStatus = statusNums[idx];
      this.carStatusTxt = statusTxtNums[idx];
    }
  },
  computed: {
    expectedChargingTime() {
      const { expectedChargingTime = 0 } = this.bettryInfo;

      if (expectedChargingTime < 60) {
        return parseInt(expectedChargingTime, 10) + '分钟';
      } else {
        return parseInt(expectedChargingTime / 60, 10) + '小时';
      }
    },
  },
  mounted() {
    document.body.style.userSelect = 'auto';
    document.body.removeAttribute('oncontextmenu');

    loadAmap().then((AMap) => {
      this.map.AMap = AMap;
    })

    this.charts.forEach((chart) => {
      this.chartRender(chart);
    });

    window.onresize = () => {
      this.isFullScreen = !!document.fullscreenElement;
    }
  },
  beforeDestroy() {
    const { timers } = this;

    Object.keys(timers).forEach((key) => {
      if (timers[key]) clearTimeout(timers[key]);
    });
  },
  methods: {
    fullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    },
    poiClick(poi) {
      if (!poi.name) return;

      const { curPoi } = this;

      if (curPoi) {
        if(curPoi.poiKey !== poi.poiKey) {
          this.curPoi = undefined;

          setTimeout(() => {
            this.curPoi = poi;
          }, 200)
        }
      } else {
        this.curPoi = poi;
      }
    },
    poiMaskerClick() {
      this.curPoi = undefined;
    },
    getPoiContent(poi) {
      const { curPoi } = this;
      const icons = [
        '',
        '<svg width="24" height="26" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M12 0c6.627 0 12 5.26 12 11.747 0 5.577-3.97 10.246-9.294 11.447L11.84 26l-2.88-2.82h.276C3.94 21.96 0 17.304 0 11.748 0 5.259 5.373 0 12 0z" fill="#61BF5A"/><path d="M14.623 13.584c-.394.993-1.413 1.697-2.617 1.697-.811 0-1.54-.32-2.05-.843l.833-.438c.325.267.753.427 1.217.427.683 0 1.273-.341 1.598-.843h-.741l1.25-1.142 1.24 1.142h-.73zm-5.965-1.708H9.4c.382-.992 1.413-1.697 2.617-1.697.822 0 1.552.33 2.062.854l-.834.438a1.955 1.955 0 0 0-1.228-.438c-.683 0-1.274.342-1.598.843h.741l-1.25 1.142-1.252-1.142zm9.243-2.092L12.283 6.08a.483.483 0 0 0-.532 0L6.099 9.784c-.186.118-.093.384.139.384h1.135v6.618c0 .118.104.214.231.214h8.803c.127 0 .231-.096.231-.214v-6.618h1.135c.22 0 .313-.266.128-.384z" fill="#FFF"/></g></svg>',
        '<svg width="24" height="26" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M12 0c6.627 0 12 5.26 12 11.747 0 5.577-3.97 10.246-9.294 11.447L11.84 26l-2.88-2.82h.276C3.94 21.96 0 17.304 0 11.748 0 5.259 5.373 0 12 0z" fill="#0062FF"/><circle fill="#FFF" cx="12" cy="12" r="8"/><text font-family="PingFangSC-Regular, PingFang SC" font-size="10" fill="#0062FF"><tspan x="7" y="16">驿</tspan></text></g></svg>',
      ];
      const { poiKey, poiType = 2, name, disabled } = poi;
      let nameStr = !disabled ? `<p>${name}</p>` : '';
      let cls = this.$style.poi;

      if (disabled) {
        cls += ` ${this.$style.disabled}`;
      }

      if (curPoi && curPoi.poiKey === poiKey) {
        cls = `${this.$style.poi} ${this.$style.actived}`;
        nameStr = `<p>${name}</p>`;
      }

      return `<div class="${cls}">
        ${icons[poiType]}
        ${nameStr}
      </div>`;
    },
    renderData(chartInfo, chart) {
      const { timers } = this;
      const interval = () => {
        if (chartInfo.interval) {
          if (timers[chartInfo.id]) clearTimeout(timers[chartInfo.id]);

          timers[chartInfo.id] = setTimeout(queryData, chartInfo.interval);
        }
      };
      const queryData = () => {
        if (chartInfo.id === "vehicleTrace") {
          chartInfo.api = this.timestamp
            ? `${chartInfo._api}&type=2&startTime=${this.timestamp}`
            : `${chartInfo._api}&type=1`;
        }

        this.axios({
          url: `/${chartInfo.api}`,
          method: "get",
        })
          .then((res) => {
            this.updatetime = dayjs().format("MM月DD日 HH:mm:ss");

            try {
              if (chartInfo.render) {
                chartInfo.render(res, chart);
              }
            } catch (error) {
              console.log("render error:", error);
            }

            interval();
          })
          .catch(() => {
            // 请求失败重试
            interval();
          });
      };

      if (!chartInfo.api && !chartInfo._api) return;

      queryData();
    },
    chartRender(chartInfo) {
      let chart;

      if (chartInfo.id && this[`${chartInfo.id}InitChart`]) {
        chart = this[`${chartInfo.id}InitChart`](chartInfo.id);
      }

      if (chartInfo.api || chartInfo._api) {
        this.renderData(chartInfo, chart);
      }
    },
    mapComplete(amap) {
      this.map.amap = amap;

      this.$nextTick(() => {
        this.renderData(this.map.config);
      });
    },
    mapMoveend() {
      // 延时等待地图资源加载
      setTimeout(() => {
        this.move();
      }, 500)
    },
    mapDragstart() {
      this.autoFit = false;

      if (this.autoFitTimer) {
        clearTimeout(this.autoFitTimer);
        this.autoFitTimer = undefined;
      }
    },
    mapDragend() {
      setTimeout(() => {
        this.autoFit = true;
      }, 30 * 1000)
    },
    onCarReady(marker) {
      this.car.marker = marker;
    },
    onCarMoving(ev) {
      // console.log('onCarMoving:', ev)

      const { path = [] } = this.car;

      this.moving = true;

      this.car.path = [
        ...path,
        ...ev.passedPath,
      ];
    },
    onCarMoveend(ev) {
      const { pathPoi = [] } = this.car;
      const { lng, lat } = ev.target.getPosition();

      this.car.path = [
        ...pathPoi,
        [lng, lat],
      ];
      this.car.pathPoi = [
        ...pathPoi,
        [lng, lat],
      ];

      this.moving = false;

      if (!this.autoFitTimer && this.autoFit) {
        this.autoFitTimer = setTimeout(() => {
          this.autoFitTimer = undefined;

          this.setFitView();
        }, 1000);
      }

      this.$nextTick(() => {
        this.move();
      })
    },
    move() {
      const { marker } = this.car;
      const { vehicleTrace, moving } = this;

      if (!marker) {
        setTimeout(this.move, 500);

        return;
      }

      // 小车移动中，直接返回
      if (moving) return;

      const carPos = marker.getPosition();
      const trace = vehicleTrace.shift();

      this.isHistory = trace && trace.ts < todayTs;

      // 轨迹队列为空，停止递归
      if (!trace) {
        this.moving = false;

        return;
      }

      // 轨迹点等于当前坐标点，会造成不移动
      if (trace.lnglat[0] === carPos.lng && trace.lnglat[1] === carPos.lat) {
        this.move();

        return;
      }

      this.$nextTick(() => {
        const { lnglat, duration } = trace;

        marker.moveTo(lnglat, {
          duration,
          autoRotation: true,
        });
      })
    },
    setFitView() {
      const polyline = this.map.amap.getAllOverlays('polyline');

      this.map.amap.setFitView(polyline, false, [16, 16, 16, 16], 16);
    },
    // 生成轨迹队列
    addVehicleTrace(vehicleTraceDataList, init) {
      const { vehicleTrace, timestamp, moveStatus } = this;
      const { AMap } = this.map;
      let initCenter = false;
      let lastTimestamp;
      let preTrace;

      if (!AMap || !vehicleTraceDataList || !vehicleTraceDataList.length) {
        this.isHistory = false;
        return;
      }

      vehicleTraceDataList.forEach((trace) => {
        const { value, ts } = trace;

        lastTimestamp = ts;

        if (!value) return;

        const [ lat, lng ] = value.split(',');
        const lnglat = [lng - 0, lat - 0];
        // duration 单位：ms
        let duration = 0;

        if (moveStatus === 'end' && init) {
          this.car.pos = lnglat;
          this.car.visible = true;

          if (!this.car.path) {
            this.car.path = [ lnglat ];
            this.car.pathPoi = [ lnglat ];
          } else {
            this.car.path.push(lnglat);
            this.car.pathPoi.push(lnglat);
          }

          return;
        }

        // 初始化地图中心、小车位置、轨迹起点
        if (!initCenter && init) {
          this.isHistory = true;
          this.map.center = lnglat;
          this.car.pos = lnglat;
          this.car.visible = true;
          this.car.path = [ lnglat ];
          this.car.pathPoi = [ lnglat ];

          initCenter = true;
        }

        if (preTrace) {
          const meters = AMap.GeometryUtil.distance(preTrace.lnglat, lnglat);

          // 5米内不移动
          if (meters < 5) return;

          duration = ts < todayTs ? (meters / 7900) * 1000 : (ts - preTrace.ts);
        } else if (timestamp) {
          duration = ts - timestamp;
        }
      
        preTrace = {
          ts,
          lnglat,
          duration,
        }

        vehicleTrace.push(preTrace);
      });

      this.timestamp = lastTimestamp;

      this.$nextTick(() => {
        this.autoFit && this.setFitView();
      });
    },
  },
};
</script>