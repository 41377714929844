import axios from 'axios'
import { MessageBox, Message} from 'element-ui'
// import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/config/errorCode'


let globalMsg


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_DOMAIN || '',
  // baseURL:`http://192.168.2.22:8080`,
  // 超时
  timeout: 50000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && getToken() != 'undefined' && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    const handlerUrl = ['/logout', '/getInfo']
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      const err = new Error(msg)

      if (globalMsg || handlerUrl.includes(res.config.url)) return Promise.reject(err)

      globalMsg = true

      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        globalMsg = false

        // store.dispatch('LogOut').then(() => {
        //   location.href = '/'
        // }).catch(() => {
        //   location.href = '/'
        // })
      }).catch(() => {
        globalMsg = false
      })
      return Promise.reject(err)
    } else if (code === 500) {
      Message({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else if (code == 800) {
      Message({
        message: msg,
        type: 'error'
      })
      return res.data
      // return Promise.reject(res.data.msg || 'error')
    } else if (code == 2001) {
      return res.data
    } else if (res.headers['content-disposition']) {
      return res
    } else {
      return res.data
    }
  },
  error => {
    let { message } = error
    if (message == 'Network Error') {
      message = '当前数据错误'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }else if (message == 'Request aborted'){
      message = '请求中断'
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
